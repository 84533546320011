import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ImageSelectModal from "../../modals/ImageSelectModal";
import ConfirmModal from "../../modals/ConfirmModal";
import DragDropForm from "../../forms/DragDropForm";
import TextInput from "../../inputs/TextInput";
import InlineError from "../../UI/InlineError";
import Button from "../../buttons/Button";
import Modal from "../../modals/Modal";
import Form from "../../layout/Form";

import { removeItem, useIndexedDBState } from "../../../hooks/useStorageState";
import useAbortSignal from "../../../hooks/useAbortSignal";

import { successfulToast } from "../../../util/toastNotifications";
import { toBase64 } from "../../../util/toBase64";
import asyncAPICall from "../../../util/apiWrapper";

const MeditationSetForm = ({ meditationSetId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [meditationSetForm, setMeditationSetForm] = useIndexedDBState(
    {
      name: "",
      imageUrl: "",
      imageId: "",
      pricePerMonth: "",
      stripePriceId: "",
      stripeProductId: "",
    },
    "meditationSetForm"
  );

  const [formErrors, setFormErrors] = useState({
    pricePerMonth: "",
    name: "",
    imageUrl: "",
    stripePriceId: "",
    stripeProductId: "",
  });

  const [isOpen, setIsOpen] = useState({
    delete: false,
    upload: false,
    imageSelect: false,
    dragDrop: false,
  });

  const inputRef = useRef(null);
  const { signal } = useAbortSignal();
  const { push } = useHistory();

  const validateForm = () => {
    const errors = {};
    const fieldsToValidate = [
      { field: "name", message: "Name cannot be empty" },
      { field: "pricePerMonth", message: "Price cannot be empty" },
      { field: "imageUrl", message: "Image required" },
      { field: "stripePriceId", message: "Stripe price ID required" },
      { field: "stripeProductId", message: "Stripe product ID required" },
    ];

    fieldsToValidate.forEach(({ field, message }) => {
      if (!meditationSetForm[field]) {
        errors[field] = message;
      }
    });

    return Object.keys(errors).length > 0 ? errors : null;
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();
    if (validationErrors) {
      setFormErrors(validationErrors);
      return;
    }

    setFormErrors({});

    setIsLoading(true);
    const payload = {
      name: meditationSetForm.name,
      image_id: meditationSetForm.imageId,
      price_per_month: meditationSetForm.pricePerMonth,
      image: meditationSetForm.image,
      stripe_price_id: meditationSetForm.stripePriceId,
      stripe_product_id: meditationSetForm.stripeProductId,
    };

    asyncAPICall(
      `/meditation-set${meditationSetId ? `/${meditationSetId}` : ""}`,
      meditationSetId ? "PUT" : "POST",
      payload,
      (res) => {
        if (res.ok) {
          successfulToast(
            meditationSetId
              ? "Meditation set edited"
              : "New meditation set created!"
          );
          push("/add-media");
          removeItem("meditationSetForm");
        } else {
          push("/add-media");
          setIsLoading(false);
        }
      },
      null,
      (err) => {
        push("/add-media");
        console.error(`meditation creation ${err}`);
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMeditationSetForm((prev) => ({ ...prev, [name]: value }));

    if (formErrors[name]) setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  const handleImageFormUpdate = (fieldsToUpdate, errorField) => {
    if (errorField) {
      setFormErrors((prev) => ({ ...prev, [errorField]: "" }));
    }

    setMeditationSetForm((prev) => ({ ...prev, ...fieldsToUpdate }));
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    const imageURL = URL.createObjectURL(file);
    handleImageFormUpdate(
      { imageUrl: imageURL, imageId: "", imageFile: file },
      "imageUrl"
    );

    handleImageChange(e);
  };

  const handleImageChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size < 3e7) {
        const imageFile = e.target.files[0];

        handleUpload(imageFile);
      } else {
        successfulToast("File too large");
        handleImageFormUpdate({ imageUrl: "", imageFile: "" });
      }
    }
  };

  const validatePriceInput = (e) => {
    const { value, maxLength } = e.target;
    const decimalIndex = value.indexOf(".");

    if (decimalIndex >= 0 && decimalIndex !== maxLength) {
      const split = value.split(".");
      if (split.length === 2 && split[1].length > 2) {
        e.target.value = value.slice(0, decimalIndex + 3);
      }
    }

    if (value.length > maxLength) {
      e.target.value = value.slice(0, maxLength);
    }
  };

  const handleUpload = async (file) => {
    if (file) {
      const file_name = file.name.slice(0, file.name.lastIndexOf("."));
      const ext = file.name.split(".")[file.name.split(".").length - 1];
      const file_type = file.type;
      await toBase64(file)
        .then((result) =>
          setMeditationSetForm((prev) => ({
            ...prev,
            image: { result, file_name, file_type, ext },
          }))
        )
        .catch((err) => console.error("Uploading Avatar", err));
    }
  };

  const handleDeleteSet = () => {
    setIsLoading(true);
    asyncAPICall(
      `/meditation-set/delete/${meditationSetId}`,
      "DELETE",
      null,
      (res) => {
        if (res.ok) {
          successfulToast("Meditation deleted");
          push("/add-media");
        } else {
          setIsLoading(false);
        }
        return res.json();
      },
      null,
      (err) => {
        console.error(`meditation delete ${err}`);
      }
    );
  };

  useEffect(() => {
    if (meditationSetId) {
      asyncAPICall(
        `/meditation-set/${meditationSetId}`,
        "GET",
        null,
        null,
        ({ results }) => {
          setMeditationSetForm({
            imageUrl: results.image.url,
            imageId: results.image.image_id,
            name: results.name,
            pricePerMonth: results.price_per_month,
            stripePriceId: results.stripe_price_id,
            stripeProductId: results.stripe_product_id,
          });
          setIsLoading(false);
        },
        (err) => {
          console.error(`Get Sets: ${err}`);
          setIsLoading(false);
        },
        signal
      );
    }
  }, [meditationSetId, signal, setMeditationSetForm]);

  return (
    <div className="mediation-form-container">
      <Form onSubmit={handleSubmit}>
        <button
          type="button"
          onClick={() => setIsOpen((prev) => ({ ...prev, dragDrop: true }))}
        >
          Image
        </button>

        <p
          className="image-uploader"
          onClick={() => {
            setIsOpen((prev) => ({ ...prev, imageSelect: true }));
          }}
        >
          <u>Select Uploaded Image</u>
        </p>

        {/* <DragDrop /> */}

        <label htmlFor="displayImg">Display Image</label>
        <div className="image-display" id="displayImg">
          {meditationSetForm.imageUrl && (
            <div className="inner-image-display">
              <img
                className="chosen-img"
                src={meditationSetForm.imageUrl}
                alt="Meditation set background"
              />

              <div
                className="close"
                onClick={() =>
                  handleImageFormUpdate({ imageUrl: "", imageId: "" })
                }
              >
                <FontAwesomeIcon
                  name="imageUrl"
                  value=""
                  icon="fa-solid fa-xmark"
                />
              </div>
            </div>
          )}
        </div>

        <input
          ref={inputRef}
          className="file-upload"
          type="file"
          accept=".jpeg, .png, .svg, .jpg"
          placeholder="Image"
          onChange={(e) => handleImage(e)}
        />
        {formErrors.imageUrl && <InlineError message={formErrors.imageUrl} />}

        <label htmlFor="file">File Name</label>
        <TextInput
          value={meditationSetForm.name}
          id="file"
          maxLength="25"
          placeholder="Enter display name for the file"
          name="name"
          onChange={handleChange}
          error={formErrors.name}
        />

        <label htmlFor="price">Set Price</label>
        <div className="number-input-wrapper">
          <span>$</span>
          <input
            id="price"
            min="0"
            max="10000"
            maxLength="7"
            type="number"
            step="0.01"
            name="pricePerMonth"
            value={meditationSetForm.pricePerMonth}
            onKeyDown={(event) => {
              if (
                event.key.toLowerCase() === "e" ||
                (meditationSetForm.pricePerMonth.length ===
                  event.target.maxLength &&
                  event.key === ".") ||
                event.key === "-" ||
                event.key === "+"
              ) {
                return event.preventDefault();
              }
            }}
            onChange={(e) => {
              validatePriceInput(e);
              handleChange(e);
            }}
          />
        </div>
        {formErrors.pricePerMonth && (
          <InlineError message={formErrors.pricePerMonth} />
        )}

        <label htmlFor="price-id">Stripe Price ID</label>
        <TextInput
          name="stripePriceId"
          value={meditationSetForm.stripePriceId}
          id="price-id"
          placeholder="Enter stripe price id"
          onChange={handleChange}
        />
        {formErrors.stripePriceId && (
          <InlineError message={formErrors.stripePriceId} />
        )}

        <label htmlFor="product-id">Stripe Product ID</label>
        <TextInput
          name="stripeProductId"
          value={meditationSetForm.stripeProductId}
          id="product-id"
          placeholder="Enter stripe product id"
          onChange={handleChange}
        />
        {formErrors.stripeProductId && (
          <InlineError message={formErrors.stripeProductId} />
        )}

        <div className="button-wrapper">
          <Button
            type="button"
            className="cancel-btn"
            onClick={() => {
              push("/add-media");
              removeItem("meditationSetForm");
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button type="Submit" disabled={isLoading}>
            Submit
          </Button>
        </div>
        {meditationSetId && (
          <p
            className="delete-set"
            onClick={() => {
              setIsOpen((prev) => ({ ...prev, delete: true }));
            }}
          >
            Delete Set
          </p>
        )}
      </Form>

      <ConfirmModal
        isOpen={isOpen.delete}
        handleClose={() => {
          setIsOpen((prev) => ({ ...prev, delete: false }));
        }}
        handleSubmit={handleDeleteSet}
        isLoading={isLoading}
      >
        <p>
          Are you sure you want to delete your mediation
          <span> *permanently?</span>
        </p>
      </ConfirmModal>

      <ImageSelectModal
        isOpen={isOpen.imageSelect}
        handleClose={() => {
          setIsOpen((prev) => ({ ...prev, imageSelect: false }));
        }}
        handleImageUpdate={handleImageFormUpdate}
        currentImageId={meditationSetForm.imageId}
      />

      <Modal
        isOpen={isOpen.dragDrop}
        content={{
          width: "335px",
          height: "525px",
          padding: "20px",
          fontSize: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          top: "44%",
          boxSizing: "border-box",
          ...(window.innerWidth >= 930 &&
            window.innerHeight >= 582 && {
              width: "792px",
              height: "541px",
              padding: "27px",
              justifyContent: "space-evenly",
              top: "45%",
            }),
          ...(window.innerHeight <= 582 &&
            window.innerWidth >= 520 && {
              width: "792px",
              height: "541px",
              padding: "23px",
              justifyContent: "space-evenly",
              top: "47%",
              transform: "scale(0.6) translate(-83.5%, -80%)",
            }),
        }}
        overlay={{
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(49, 53, 62, 0.5)",
          zIndex: "3",
        }}
        onRequestClose={() =>
          setIsOpen((prev) => ({ ...prev, dragDrop: false }))
        }
      >
        <DragDropForm
          handleFile={handleImage}
          handleClose={() =>
            setIsOpen((prev) => ({ ...prev, dragDrop: false }))
          }
          accept={[".jpeg", ".png", ".svg", ".jpg"]}
          fileType="image"
        />
      </Modal>
    </div>
  );
};

export default MeditationSetForm;
