import WhispBackground from "../../core/WhispBackground";
import MeditationSetForm from "./MeditationSetForm";
import HeaderOne from "../../core/HeaderOne";

const MeditationSetAdmin = (props) => {
  const { meditationSetId } = props.match.params;

  return (
    <div className="meditation-set-container ">
      <HeaderOne>Meditation Sets</HeaderOne>

      <h3>{meditationSetId ? "Edit Meditation Set" : "New Meditation Set"}</h3>
      <p className="instructions">
        Upload a display image for your meditation set
      </p>

      <MeditationSetForm meditationSetId={meditationSetId} />

      <WhispBackground />
    </div>
  );
};

export default MeditationSetAdmin;
