import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../../buttons/Button";

import useAbortSignal from "../../../hooks/useAbortSignal";

import asyncAPICall from "../../../util/apiWrapper";
import { errorToast, successfulToast } from "../../../util/toastNotifications";

const OrderMeditationsForm = ({
  closeModal,
  meditations,
  meditationSetId,
  setMeditationSet,
}) => {
  const [reorderedMeditations, setReorderedMeditations] = useState(meditations);
  const [draggedOverMeditation, setDraggedOverMeditation] = useState("");
  const [dragMeditation, setDragMeditation] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { signal } = useAbortSignal();

  const handleReorder = () => {
    setReorderedMeditations((prev) => {
      const reorderedMeditations = [...prev];
      const dragIndex = reorderedMeditations.findIndex(
        (meditation) => meditation.meditation_id === dragMeditation
      );
      const draggedOverIndex = reorderedMeditations.findIndex(
        (meditation) => meditation.meditation_id === draggedOverMeditation
      );

      const temp = reorderedMeditations[dragIndex];
      reorderedMeditations[dragIndex] = reorderedMeditations[draggedOverIndex];
      reorderedMeditations[draggedOverIndex] = temp;

      return reorderedMeditations;
    });

    setDragMeditation("");
    setDraggedOverMeditation("");
  };

  const handleSave = () => {
    const payload = {
      meditations: reorderedMeditations.map(
        (meditation) => meditation.meditation_id
      ),
    };

    asyncAPICall(
      `/meditation-set-reorder-meditations/${meditationSetId}`,
      "PATCH",
      payload,
      (res) => {
        if (res.ok) {
          successfulToast("Ordered Meditations");
        } else {
          errorToast("Failed to Order Meditations");
        }

        setIsLoading(false);
        return res.json();
      },
      ({ results }) => {
        setMeditationSet(results);
        closeModal();
      },
      (err) => {
        if (!signal.aborted) {
          console.error(err);
        }
      },
      signal
    );
    setIsLoading(true);
  };

  const isSelected = (meditation) => {
    return (
      meditation.meditation_id === dragMeditation ||
      meditation.meditation_id === draggedOverMeditation
    );
  };

  const renderMeditationItems = () => {
    return reorderedMeditations.map((meditation) => {
      return (
        <div
          className={`meditation-item ${
            isSelected(meditation) ? "selected" : ""
          }`}
          key={meditation.meditation_id}
          draggable
          onDragStart={() => setDragMeditation(meditation.meditation_id)}
          onDragEnter={() => setDraggedOverMeditation(meditation.meditation_id)}
          onDragEnd={handleReorder}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className="name">{meditation.name}</div>
          <div className="title">{meditation.title}</div>
          <FontAwesomeIcon
            className="drag-icon"
            icon="fa-solid fa-grip-vertical"
          />
        </div>
      );
    });
  };

  return (
    <div className="order-meditations-form-container">
      <h2>Order Meditations</h2>

      <p className="meditations-text">
        {meditations.length === 0
          ? "Please add some meditations first before changing their order"
          : "Drag your meditations to the order you want"}
      </p>

      <div className="order-meditations-wrapper">{renderMeditationItems()}</div>

      <div className="buttons-wrapper">
        <Button className="cancel-btn" onClick={closeModal}>
          Cancel
        </Button>

        {meditations.length !== 0 && (
          <Button
            className="save-btn"
            onClick={handleSave}
            disabled={isLoading}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

export default OrderMeditationsForm;
