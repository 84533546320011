import { Route, Switch, Redirect } from "react-router-dom";

import PaymentConfirmation from "../pages/payment/PaymentConfirmation";
import PremiumSounds from "../pages/meditation/PremiumSounds";
import Subscriptions from "../pages/subscriptions/Subscriptions";
import MeditationRoutes from "./MeditationRoutes";
import Payment from "../pages/payment/Payment";
import Account from "../pages/account/Account";
import JournalRoutes from "./JournalRoutes";
import Header from "../navigation/Header";
import AdminRoutes from "./AdminRoutes";
import Loading from "../core/Loading";

import { useAuthInfo } from "../../context/AuthContext";

const PrivateRoutes = () => {
  const { isLoading, user } = useAuthInfo();

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  } else if (!user) {
    return <Redirect to="/home" />;
  }

  return (
    <div className="body-container">
      <Route path="/" component={Header} />

      <Switch>
        <Route path="/account" component={Account} />
        <Route path="/subscriptions" component={Subscriptions} />
        <Route path="/payment/:priceId" component={Payment} />
        <Route
          path="/payment-confirmation/:sessionId"
          component={PaymentConfirmation}
        />
        <Route exact path="/payment-method" component={Payment} />
        <Route
          path="/payment-method-confirm/:sessionId"
          component={PaymentConfirmation}
        />

        <Route path="/meditation" component={MeditationRoutes} />
        <Route exact path="/premium-meditations" component={PremiumSounds} />
        <Route path="/add-media" component={AdminRoutes} />
        <Route path="/journal" component={JournalRoutes} />
        <Redirect to="/meditation/home" />
      </Switch>
    </div>
  );
};

export default PrivateRoutes;
