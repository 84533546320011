const CircleArrow = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1094 0C18.7988 0 24.2188 5.41992 24.2188 12.1094C24.2188 18.7988 18.7988 24.2188 12.1094 24.2188C5.41992 24.2188 0 18.7988 0 12.1094C0 5.41992 5.41992 0 12.1094 0ZM17.6709 11.2793L11.0547 4.66309C10.5957 4.2041 9.85352 4.2041 9.39941 4.66309L8.56934 5.49316C8.11035 5.95215 8.11035 6.69434 8.56934 7.14844L13.5303 12.1094L8.56934 17.0703C8.11035 17.5293 8.11035 18.2715 8.56934 18.7256L9.39941 19.5557C9.8584 20.0146 10.6006 20.0146 11.0547 19.5557L17.6709 12.9395C18.1299 12.4805 18.1299 11.7383 17.6709 11.2793Z"
        fill="#171717"
      />
    </svg>
  );
};

export default CircleArrow;
