import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InlineError from "../UI/InlineError";
import Button from "../buttons/Button";

const PasswordInput = ({ error, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="password-field">
        <input type={showPassword ? "text" : "password"} {...props} />

        <Button
          className="show-hide"
          type="button"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          <FontAwesomeIcon
            icon={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
            size="lg"
          />
        </Button>
      </div>

      {error && <InlineError message={error} />}
    </>
  );
};

export default PasswordInput;
