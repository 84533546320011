import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { errorToast } from "../../util/toastNotifications";

export default function DragDrop({ setUploadedFile, accept }) {
  const inputRef = useRef();

  const mimeDict = {
    ".mp3": ["audio/mpeg", "audio/mp3"],
    ".wav": ["audio/wav", "audio/wave", "audio/x-wav", "audio/x-pn-wav"],
    ".m4a": ["audio/mp4", "audio/x-m4a"],
    ".jpeg": ["image/jpeg", "image/pjpeg", "image/jpg"],
    ".png": ["image/png", "image/x-png"],
    ".svg": ["image/svg+xml", "application/svg+xml"],
    ".jpg": ["image/jpeg", "image/pjpeg", "image/jpg"],
  };

  const handleFileChange = (e) => {
    const file = e.target?.files.length > 0 && e.target.files[0];

    if (file?.type) {
      const isValid = accept.reduce((acc, cV) => {
        if (mimeDict[cV].includes(file.type)) acc = true;

        return acc;
      }, false);

      if (isValid) {
        setUploadedFile(e);
      } else {
        errorToast("Unsupported File Type");
        inputRef.current.value = null;
      }
    }
  };

  return (
    <div className="drag-drop-wrapper">
      <div className="heading-wrapper">
        Select the item you want to upload by drag and drop or by searching your
        device.
      </div>

      <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" />

      <div className="button-wrapper">
        <div>
          Drag & Drop <br /> or{" "}
        </div>

        <button onClick={() => inputRef.current.click()}>Select File</button>
      </div>

      <input
        ref={inputRef}
        type="file"
        accept={accept.join(", ")}
        title=""
        onChange={handleFileChange}
      />
    </div>
  );
}
