import { Link } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import Card from "../../core/Card";

import composer from "../../../assets/images/banners/about.jpg";
import sounds from "../../../assets/images/banners/sounds.jpg";

function About() {
  return (
    <div className="about-container">
      <div className="banner">About</div>

      <div className="card-wrapper">
        <Link className="card-link" to="/about-composer">
          <Card
            className="composer"
            alt="Melissa Winterton portrait"
            src={composer}
          >
            About the Composer
          </Card>
        </Link>

        <Link className="card-link" to="/about-sounds">
          <Card
            className="composer"
            alt="Inside a cathedral with stained glass windows"
            src={sounds}
          >
            About the Sounds
          </Card>
        </Link>
      </div>

      <WhispBackground />
    </div>
  );
}

export default About;
