import { Route, Switch, Redirect } from "react-router-dom";

import ResetPassword from "../pages/auth/ResetPassword";
import EmailSentPage from "../pages/auth/EmailSentPage";
import Register from "../pages/auth/Register";
import Login from "../pages/auth/Login";

import { useAuthInfo } from "../../context/AuthContext";

export default function AuthRoutes() {
  const { user } = useAuthInfo();

  if (user) return <Redirect to="/meditation/home" />;

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />

      <Route
        path="/reset-password/:passwordResetTokenId?"
        render={(routeProps) => {
          return <ResetPassword {...routeProps} />;
        }}
      />
      <Route
        path="/verify-email/:verifyEmailTokenId?"
        component={EmailSentPage}
      />
    </Switch>
  );
}
