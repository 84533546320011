import Bell from "../../assets/icons/Bell.svg";

const BellIcon = () => {
  return (
    <div className="bell-icon">
      <img src={Bell} alt="bell icon" />
    </div>
  );
};
export default BellIcon;
