import WhispBackground from "../../core/WhispBackground";
import ImageCarousel from "../../core/ImageCarousel";
import HeaderOne from "../../core/HeaderOne";
import Button from "../../buttons/Button";

import { gates } from "../../../helpers/gateImages";

const Gates = (props) => {
  return (
    <div className="meditation-content">
      <HeaderOne>Step Two</HeaderOne>

      <div className="page-wrapper">
        <h3>Picture a gate in your mind</h3>

        <p>
          To begin the process, you first design a gate in your mind. Everyone's
          gate is different.
        </p>

        <p>
          If you need help visualizing a gate, there are some examples below.
        </p>

        <p>
          The key is to not overthink it and to just relax and have fun. Your
          gate can even change from time to time as you progress.
        </p>

        <p> Trust your intuition! This is a natural process.</p>
      </div>

      <div className="gate-pagination">
        <ImageCarousel images={gates} />
      </div>

      <div className="button-wrapper">
        <Button onClick={() => props.history.push("/meditation/instructions")}>
          I See It
        </Button>
      </div>

      <WhispBackground />
    </div>
  );
};

export default Gates;
