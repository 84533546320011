import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import Loading from "../../core/Loading";

import asyncAPICall from "../../../util/apiWrapper";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment = (props) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { priceId } = props.match.params;
  const url = priceId
    ? `/stripe-create-checkout-session/${priceId}`
    : "/stripe-change-payment-method-checkout-session";

  useEffect(() => {
    if (!clientSecret && isLoading) {
      asyncAPICall(
        url,
        "POST",
        null,
        null,
        (data) => {
          setIsLoading(false);
          setClientSecret(data.client_secret);
        },
        (err) => {
          setIsLoading(false);
          console.error("error creating checkout session: ", err);
        }
      );
    }
  }, [clientSecret, priceId, url, isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="checkout-container">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default Payment;
