import { useEffect, useState } from "react";

import SubscriptionModal from "../../modals/SubscriptionModal";
import RenderMeditationSets from "./RenderMeditationSets";
import WhispBackground from "../../core/WhispBackground";
import HeaderOne from "../../core/HeaderOne";
import Loading from "../../core/Loading";

import { successfulToast } from "../../../util/toastNotifications";
import { useAuthInfo } from "../../../context/AuthContext";
import useAbortSignal from "../../../hooks/useAbortSignal";
import asyncAPICall from "../../../util/apiWrapper";

const MeditationSets = () => {
  const [selectedMeditation, setSelectedMeditation] = useState(null);
  const [confirmButtons, setConfirmButtons] = useState(false);
  const [subbedSetOrder, setSubbedSetOrder] = useState(null);
  const [meditationSets, setMeditationSets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const { user, setUser } = useAuthInfo();
  const { signal } = useAbortSignal();

  const changeSubscription = (type, cancel = false) => {
    asyncAPICall(
      "/stripe-upgrade-downgrade",
      "PATCH",
      {
        stripe_price_id: `${selectedMeditation.stripe_price_id}`,
        sub_action: type,
        cancel: cancel,
      },
      null,
      (data) => {
        setUser(data.result);
        if (data) {
          successfulToast(
            `Successfully ${type}d ${type === "subscribe" ? "to" : "from"} ${
              selectedMeditation.name
            }`
          );
        }
        setIsOpen(false);
        setConfirmButtons(false);
      },
      (err) => {
        console.error(err);
        setIsOpen(false);
        setConfirmButtons(false);
      },
      signal
    );
  };

  useEffect(() => {
    asyncAPICall(
      `/meditation-sets`,
      "GET",
      null,
      null,
      ({ results }) => {
        setMeditationSets(results);
        setIsLoading(false);

        const filteredData = results.filter(
          (set) =>
            set.stripe_price_id ===
            user.stripe_info?.subscribed_sets[
              user.stripe_info?.subscribed_sets.length - 1
            ]
        );
        setSubbedSetOrder(filteredData.length > 0 ? filteredData[0].order : 0);
      },
      (err) => {
        console.error(`Get Sets: ${err}`);
        setIsLoading(false);
      },
      signal
    );
  }, [signal, user.stripe_info?.subscribed_sets]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="meditation-content">
      <HeaderOne>Step Three</HeaderOne>

      <h3>Select Meditation Set</h3>
      <div className="card-wrapper">
        <RenderMeditationSets
          setSelectedMeditation={setSelectedMeditation}
          subbedSetOrder={subbedSetOrder}
          meditationSets={meditationSets.sort((a, b) => a.order - b.order)}
          setIsOpen={setIsOpen}
          user={user}
        />
      </div>

      <WhispBackground />

      {selectedMeditation?.order === subbedSetOrder + 1 &&
        user.role !== "super-admin" && (
          <SubscriptionModal
            selectedMeditation={selectedMeditation}
            changeSubscription={changeSubscription}
            setConfirmButtons={setConfirmButtons}
            confirmButtons={confirmButtons}
            subbedSetOrder={subbedSetOrder}
            subType={"subscribe"}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            user={user}
          />
        )}
    </div>
  );
};

export default MeditationSets;
