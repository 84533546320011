import { Link } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import LoginForm from "../../registration/LoginForm";
import ResonanceLogo from "../../UI/ResonanceLogo";

const Login = () => {
  return (
    <div className="login-container">
      <ResonanceLogo />
      <LoginForm />

      <Link className="create-account-link" to="/register">
        Create an Account
      </Link>

      <WhispBackground mode="dark" />
    </div>
  );
};

export default Login;
