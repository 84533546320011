import { useEffect, useState } from "react";

import WhispBackground from "../../core/WhispBackground";
import MeditationEntryForm from "./MeditationEntryForm";
import HeaderOne from "../../core/HeaderOne";
import Loading from "../../core/Loading";

import useAbortSignal from "../../../hooks/useAbortSignal";
import asyncAPICall from "../../../util/apiWrapper";

const MeditationEditEntry = (props) => {
  const [meditationJournal, setMeditationJournal] = useState(null);
  const [meditation, setMeditation] = useState(
    props.location?.state?.meditation
  );
  const [isLoading, setIsLoading] = useState(false);
  const { signal } = useAbortSignal();
  const { meditationJournalId } = props.match.params;

  useEffect(() => {
    if (meditationJournalId) {
      setIsLoading(true);

      asyncAPICall(
        `/journal-entry/${meditationJournalId}`,
        "GET",
        null,
        null,
        ({ results }) => {
          setMeditationJournal(results);
          setMeditation(results.meditation);
          setIsLoading(false);
        },
        (err) => {
          console.error(`Get Meditation Entry: ${err}`);
          setIsLoading(false);
        },
        signal
      );
    }
  }, [meditationJournalId, signal]);

  if (isLoading) {
    return <Loading>Loading Entry</Loading>;
  }

  return (
    <div className="meditation-content">
      <HeaderOne
        style={{
          backgroundImage: `url(${meditation?.image?.url})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {meditation?.name}
      </HeaderOne>

      <div className="page-wrapper">
        <h3>Meditation Journal</h3>
        <MeditationEntryForm
          meditationId={meditation?.meditation_id}
          meditationJournal={meditationJournal ? meditationJournal : null}
        />
      </div>

      <WhispBackground />
    </div>
  );
};

export default MeditationEditEntry;
