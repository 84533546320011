export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const b64Result = event.target.result;

      resolve(b64Result);
    };

    reader.onerror = (error) => reject(error);
  });
