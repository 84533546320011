import WhispBackground from "../../core/WhispBackground";
import HeaderOne from "../../core/HeaderOne";
import GratitudeForm from "./GratitudeForm";

const Gratitude = () => {
  return (
    <div className="meditation-content">
      <HeaderOne>Step One</HeaderOne>

      <div className="page-wrapper">
        <h3>Gratitude Journal</h3>

        <p>
          Think about and/or write down three things that you are grateful for.
        </p>

        <p>
          It is important that you put yourself in an attitude of gratitude!
          Being grateful for the good things in your life will put you in a
          higher place spiritually and mentally. It will allow you to connect
          and receive higher knowledge.
        </p>

        <p>
          Your gratitude thoughts will be saved for you and can be reviewed at
          any time. How uplifting it is to review all of the things you're
          grateful for!
        </p>

        <GratitudeForm />
      </div>

      <WhispBackground />
    </div>
  );
};

export default Gratitude;
