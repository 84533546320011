import { useEffect, useRef } from "react";

export default function useClickOutside(handler) {
  const domNode = useRef();

  useEffect(() => {
    const listener = (event) => {
      if (domNode.current && !domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("click", listener);
    };
  });

  return domNode;
}
