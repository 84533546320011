const ResetSoundIcon = ({ active }) => {
  return (
    <svg
      width="42"
      height="33"
      viewBox="0 0 42 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3779_2708)">
        <path
          d="M26.1631 16.7133L25.9997 16.9025L26.1631 16.7133L17.5691 9.29146C17.5691 9.29146 17.5691 9.29146 17.5691 9.29145C17.1439 8.92418 17.1434 8.26377 17.5691 7.89605C17.5691 7.89604 17.5691 7.89604 17.5691 7.89603L26.163 0.474904L26.1631 0.474896C26.7558 -0.0369883 27.6875 0.380682 27.6875 1.1726V5.08144V5.32859L27.9346 5.33142C31.8511 5.37626 35.3147 5.79266 37.7936 7.09635C39.0274 7.74525 40.0123 8.61151 40.6905 9.75909C41.369 10.9072 41.75 12.3532 41.75 14.1761C41.75 17.0789 39.8709 19.9819 37.7839 21.4999L37.931 21.702L37.7839 21.4999C37.5446 21.674 37.2542 21.6546 37.0402 21.5146C36.8289 21.3763 36.7141 21.1373 36.7987 20.8669L36.5601 20.7923L36.7987 20.8669C37.9126 17.3046 37.6867 14.9318 36.0881 13.4594C35.3009 12.7343 34.2054 12.2539 32.8459 11.9464C31.4854 11.6388 29.8421 11.5003 27.9411 11.4729L27.6875 11.4693V11.7229V16.0156C27.6875 16.8085 26.7553 17.2248 26.1631 16.7133Z"
          fill={active ? "#E8CE80" : "#FFF"}
          stroke="#171717"
          strokeWidth="0.5"
        />
        <path
          d="M15.8369 15.1617L16.0003 14.9725L15.8369 15.1617L24.4309 22.5835C24.4309 22.5835 24.4309 22.5835 24.4309 22.5835C24.8561 22.9508 24.8566 23.6112 24.4309 23.979C24.4309 23.979 24.4309 23.979 24.4309 23.979L15.837 31.4001L15.8369 31.4001C15.2442 31.912 14.3125 31.4943 14.3125 30.7024V26.7936V26.5464L14.0654 26.5436C10.1489 26.4987 6.68531 26.0823 4.20642 24.7786C2.97258 24.1297 1.98771 23.2635 1.30955 22.1159C0.631048 20.9678 0.250006 19.5218 0.250006 17.6989C0.250006 14.7961 2.12908 11.8931 4.21605 10.3751L4.069 10.173L4.21606 10.3751C4.45541 10.201 4.74576 10.2204 4.95979 10.3604C5.17115 10.4987 5.28587 10.7377 5.20134 11.0081L5.43995 11.0827L5.20134 11.0081C4.08742 14.5704 4.31334 16.9432 5.91192 18.4156C6.69905 19.1407 7.7946 19.6211 9.15412 19.9286C10.5146 20.2362 12.1579 20.3747 14.0589 20.4021L14.3125 20.4057V20.1521V15.8594C14.3125 15.0665 15.2447 14.6502 15.8369 15.1617Z"
          fill={active ? "#E8CE80" : "#FFF"}
          stroke="#171717"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3779_2708"
          x="0"
          y="0"
          width="42"
          height="32.875"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.34902 0 0 0 0 0.305882 0 0 0 0 0.235294 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3779_2708"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3779_2708"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ResetSoundIcon;
