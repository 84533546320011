import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PaginationButtons = ({ currentPage, setCurrentPage, totalPages }) => {
  const renderPageButtons = () => {
    if (totalPages <= 7) {
      return Array.from({ length: totalPages }, (_, index) => index + 1).map(
        (pageNum) => {
          return (
            <button
              key={pageNum}
              className="page-btn button-text-alt-number"
              onClick={() => setCurrentPage(pageNum)}
              disabled={currentPage === pageNum}
            >
              {pageNum}
            </button>
          );
        }
      );
    } else {
      let pageNumArr = [
        1,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        totalPages,
      ];

      if (currentPage <= 4) {
        pageNumArr = [1, 2, 3, 4, 5, totalPages];
      } else if (currentPage >= totalPages - 3) {
        pageNumArr = [
          1,
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      }

      return pageNumArr.reduce((acc, pageNum, idx) => {
        if (currentPage < totalPages - 3) {
          if (idx === pageNumArr.length - 1) {
            acc.push(
              <button
                key="right-ellipses"
                className="page-btn ellipses button-text-alt-number"
                disabled={true}
              >
                ...
              </button>
            );
          }
        }

        if (currentPage > 4) {
          if (idx === 1) {
            acc.push(
              <button
                key="left-ellipses"
                className="page-btn ellipses button-text-alt-number"
                disabled={true}
              >
                ...
              </button>
            );
          }
        }

        acc.push(
          <button
            key={pageNum}
            className="page-btn button-text-alt-number"
            onClick={() => setCurrentPage(pageNum)}
            disabled={currentPage === pageNum}
          >
            {pageNum}
          </button>
        );

        return acc;
      }, []);
    }
  };

  return (
    <div className="pagination-buttons-wrapper">
      <button
        className="arrow-btn button-text-alt"
        onClick={() => setCurrentPage((prev) => prev - 1)}
        disabled={currentPage === 1}
      >
        <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
      </button>

      {renderPageButtons()}

      <button
        className="arrow-btn button-text-alt"
        onClick={() => setCurrentPage((prev) => prev + 1)}
        disabled={currentPage >= totalPages}
      >
        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
      </button>
    </div>
  );
};

export default PaginationButtons;
