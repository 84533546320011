import { version as uuidVersion, validate as uuidValidate } from "uuid";

function validateUUID(uuid_string) {
  return uuidValidate(uuid_string) && uuidVersion(uuid_string) === 4; // ⇨ false
}

function formatPhone(phoneNumber) {
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");

  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return phoneNumber;
}

const convertHexColorToRgb = (hexColor) => {
  let fontColor = "";

  if (!hexColor.includes("#")) {
    return;
  }

  const splitHash = hexColor.split("#")[1];
  const hexSplit = splitHash.match(/.{1,2}/g);

  const rgbValues = [
    parseInt(hexSplit[0], 16),
    parseInt(hexSplit[1], 16),
    parseInt(hexSplit[2], 16),
  ];

  const addRgbValues =
    rgbValues[0] * 0.299 + rgbValues[1] * 0.587 + rgbValues[2] * 0.114;

  fontColor = addRgbValues < 160 ? "#ffffff" : "#000000";

  return fontColor;
};

function isValidEmail(email) {
  if (/^\w+([_+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
    return true;
  } else {
    return false;
  }
}

function isValidPassword(password) {
  const passwordRegEx =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/i;

  if (passwordRegEx.test(password)) {
    return true;
  } else {
    return false;
  }
}

function isValidPhoneNumber(phone) {
  const basicNumberCheck =
    /^[0-9]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  return phone.match(basicNumberCheck) !== null;
}

function isValidUSZip(zip) {
  return /^\d{5}(-\d{4})?$/.test(zip);
}

const specialChar = [
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "<",
  ">",
  "?",
  "/",
];

const checkForSpecialChar = (password) => {
  for (let char of specialChar) {
    if (password.includes(char)) {
      return true;
    }
  }
  return false;
};

const validatePassword = (password) => {
  const result = {
    isValid: true,
    errorMsg: "",
  };

  if (!password.trim() || 8 > password.length || password.length > 16) {
    result.isValid = false;
    result.errorMsg = "Password must be between 8 and 20 characters.";
  }

  if (!checkForSpecialChar(password)) {
    result.isValid = false;
    result.errorMsg = "Password must contain at least one special character.";
  }

  return result;
};

const removeEmptyLines = (str) => {
  const stringArray = str.split("\n");

  const filteredArray = stringArray.filter((line) => line !== "");

  const result = filteredArray.join("\n");

  return result;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
  validateUUID,
  validatePassword,
  formatPhone,
  convertHexColorToRgb,
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
  isValidUSZip,
  removeEmptyLines,
  capitalizeFirstLetter,
};
