import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageFooter = () => {
  const copyRightYear = new Date().getFullYear();

  return (
    <div className="footer-content">
      <div className="footer-background"></div>

      <div className="contact-us-text">
        <h3>Contact Us</h3>
      </div>

      <div className="line"></div>

      <div className="social-media">
        <div className="icon">
          <FontAwesomeIcon icon="fa-brands fa-instagram" />
          <a href="https://www.instagram.com/">Instagram</a>
        </div>

        <div className="icon">
          <FontAwesomeIcon icon="fa-brands fa-square-facebook" />
          <a href="https://www.facebook.com/resonancemeditations">Facebook</a>
        </div>

        <div className="icon">
          <FontAwesomeIcon icon="fa-solid fa-envelope" />
          <a href="mailto:example@email.com">Email</a>
        </div>
      </div>

      <div className="copyright">Copyright @ {copyRightYear}</div>
    </div>
  );
};

export default PageFooter;
