import Journal from "./Journals";

const GratitudeJournal = () => {
  return (
    <Journal
      journalType="gratitude"
      getEndpoint="gratitude-entries"
      updateEndpoint="gratitude-entry"
    />
  );
};

export default GratitudeJournal;
