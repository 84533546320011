import XButton from "../../images/xButton.svg";

const XIcon = () => {
  return (
    <div className="x-icon">
      <img src={XButton} alt="X icon" />
    </div>
  );
};
export default XIcon;
