import asyncAPICall from "./apiWrapper";
import { errorToast } from "./toastNotifications";

export async function indexedDBHandler() {
  function offlineFetch(request, db, callback) {
    asyncAPICall(
      request.url.replace("http://localhost:8089", ""),
      request.request.method,
      JSON.parse(request.request.body),
      (res) => {
        if (res.ok) {
          const deleteTransaction = db.transaction("requests", "readwrite");
          const deleteObjectStore = deleteTransaction.objectStore("requests");

          const deleteRequest = deleteObjectStore.delete(request.url);

          deleteRequest.onerror = () => {
            console.error("error deleting object in store");
          };
          deleteRequest.onsuccess = () => {
            callback();
          };
        }
      }
    );
  }

  window.addEventListener("online", () => {
    const openRequest = indexedDB.open("offlineDB", 1);

    openRequest.onerror = (event) =>
      console.error("indexedDB error", event.target.error);

    openRequest.onsuccess = (event) => {
      const db = event.target.result;

      if (db.objectStoreNames.length === 0) {
        console.error("no data saved to indexedDB");
        return;
      }

      const objectStore = db
        .transaction("requests", "readwrite")
        .objectStore("requests");
      const request = objectStore.getAll();

      request.onerror = (event) =>
        console.error("error fetching request:", event.target.error);

      request.onsuccess = (event) => {
        const requestData = event.target.result;

        if (requestData) {
          let completedRequests = 0;
          requestData.forEach((request) => {
            offlineFetch(request, db, () => {
              completedRequests++;
              if (completedRequests === requestData.length) {
                window.location.reload();
              }
            });
          });
        }
      };
    };
  });

  window.addEventListener("offline", () => {
    errorToast("Currently offline");
  });
}
