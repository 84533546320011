import { useEffect } from "react";

import WhispBackground from "../../core/WhispBackground";
import SpiritualGiftsForm from "./SpiritualGiftsForm";
import HeaderOne from "../../core/HeaderOne";

import { useCurrentMeditationContext } from "../../../context/CurrentMeditationContext";

const SpiritualGifts = (props) => {
  const { currentMeditation } = useCurrentMeditationContext();

  useEffect(() => {
    if (!currentMeditation) {
      props.history.push("/meditation/sets");
    }
  }, [currentMeditation, props.history]);

  return (
    <div className="meditation-content">
      <HeaderOne
        className="image-header"
        backgroundImage={currentMeditation?.image.url}
      >
        {currentMeditation?.name}
      </HeaderOne>

      <div className="page-wrapper">
        <h2>Spiritual Gifts</h2>

        <div className="gifts">{currentMeditation?.description}</div>

        <h2>Additional Gifts I've Found</h2>

        <SpiritualGiftsForm />
      </div>

      <WhispBackground />
    </div>
  );
};

export default SpiritualGifts;
