import { Route, Switch } from "react-router-dom";

import Footer from "./navigation/Footer";

import PrivateRoutes from "./routing/PrivateRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import AuthRoutes from "./routing/AuthRoutes";
import Loading from "./core/Loading";

import ScrollToTop from "../helpers/ScrollToTop";
import { authRoutes, publicRoutes } from "../helpers/routeLists";
import { useAuthInfo } from "../context/AuthContext";

import solidIcons from "../assets/icons/solidIcons";
import brandIcons from "../assets/icons/brandIcons";

import "../styles/app.scss";

solidIcons();
brandIcons();

function App() {
  const { isLoading, user } = useAuthInfo();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <ScrollToTop />

      <Switch>
        <Route path={authRoutes} component={AuthRoutes} />
        <Route path={publicRoutes} component={PublicRoutes} />
        <Route component={PrivateRoutes} />
      </Switch>

      {user && <Footer />}
    </div>
  );
}

export default App;
