import { Switch, Route, Redirect } from "react-router-dom";

import JournalHome from "../pages/journal/JournalHome";
import GratitudeJournal from "../pages/journal/GratitudeJournal";
import MeditationJournal from "../pages/journal/MeditationJournal";
import GratitudeEditEntry from "../pages/journal/GratitudeEditEntry";
import MeditationEditEntry from "../pages/journal/MeditationEditEntry";

const JournalRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/journal" to="journal/home" />

      <Route path="/journal/home" component={JournalHome} />
      <Route exact path="/journal/gratitude" component={GratitudeJournal} />
      <Route exact path="/journal/meditation" component={MeditationJournal} />
      <Route path="/journal/gratitude/add" component={GratitudeEditEntry} />
      <Route
        path="/journal/gratitude/edit/:gratitudeJournalId"
        component={GratitudeEditEntry}
      />
      <Route
        path="/journal/meditation/edit/:meditationJournalId"
        component={MeditationEditEntry}
      />
      <Route path="/journal/add-meditation" component={MeditationEditEntry} />
    </Switch>
  );
};

export default JournalRoutes;
