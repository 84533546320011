import { useState } from "react";
import { Link } from "react-router-dom";

import EmailInput from "../inputs/EmailInput";
import Button from "../buttons/Button";
import Label from "../inputs/Label";
import Form from "../layout/Form";

import asyncAPICall from "../../util/apiWrapper";
import { isValidEmail } from "../../util/stringUtils";

const RequestResetPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email.");
      return;
    }

    setIsLoading(true);

    asyncAPICall(
      "/password-change-request",
      "POST",
      {
        email,
      },
      (res) => {
        if (res.status === 404) {
          setErrorMessage("No user found with that email");
        } else if (res.status === 201) {
          setIsEmailSent(true);
        }

        setIsLoading(false);
      },
      null,
      (err) => {
        console.error("error sending email", err);
        setIsLoading(false);
      },
      null,
      false
    );
  };

  if (isEmailSent) {
    return (
      <p>{`We've sent a recovery link to ${email}. Please check your email.`}</p>
    );
  }

  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <p>We’ll send you an email to reset your password</p>

      <Label htmlFor="email">Email</Label>
      <EmailInput
        id="email"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />

      <div className="link-wrapper">
        <Link to="/login">Login</Link>
      </div>

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <Button type="submit" disabled={isLoading}>
        Submit
      </Button>
    </Form>
  );
};

export default RequestResetPasswordForm;
