import { Link } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import HeaderOne from "../../core/HeaderOne";
import Card from "../../core/Card";

import Clouds from "../../../assets/images/clouds.jpg";
import Flower from "../../../assets/images/flower.jpg";

const JournalHome = () => {
  return (
    <div className="journal-home-content">
      <HeaderOne>Journals</HeaderOne>

      <div className="card-wrapper">
        <Link to="/journal/gratitude">
          <Card src={Clouds} alt="Sunset on a lake">
            Gratitude Journal
          </Card>
        </Link>

        <Link to="/journal/meditation">
          <Card src={Flower} alt="Pink Lotus flower floating on water">
            Meditation Journal
          </Card>
        </Link>
      </div>

      <WhispBackground />
    </div>
  );
};

export default JournalHome;
