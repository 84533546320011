import BellIcon from "../components/core/BellIcon";
import LockIcon from "../components/core/LockIcon";

const renderLockOrBell = (idx, subbedSetOrder, userRole) => {
  if (idx + 1 <= subbedSetOrder || userRole === "super-admin") {
    return;
  } else if (idx === subbedSetOrder || (!subbedSetOrder && idx === 0)) {
    return <BellIcon />;
  } else {
    return <LockIcon />;
  }
};

export { renderLockOrBell };
