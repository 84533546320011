import { useState } from "react";
import { useHistory } from "react-router-dom";

import PasswordInput from "../inputs/PasswordInput";
import Button from "../buttons/Button";
import Label from "../inputs/Label";
import Form from "../layout/Form";

import asyncAPICall from "../../util/apiWrapper";
import { errorToast, successfulToast } from "../../util/toastNotifications";
import { validatePassword } from "../../util/stringUtils";

const INITIAL_FORM_STATE = {
  newPassword: "",
  confirmPassword: "",
};

const ResetPasswordForm = ({ passwordResetTokenId }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const validateForm = () => {
    const errors = {};

    const { isValid, errorMsg } = validatePassword(formData.newPassword);

    if (!isValid) {
      errors.newPassword = errorMsg;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      errors.confirmPassword = "passwords don't match";
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));

    if (formErrors[name]) setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  const handleUpdatePassword = () => {
    const validationErrors = validateForm();
    if (validationErrors) {
      setFormErrors(validationErrors);
      return;
    }

    setIsLoading(true);

    const payload = {
      token: passwordResetTokenId,
      new_password: formData.newPassword,
    };

    asyncAPICall(
      "/forgot-password-change",
      "POST",
      payload,
      (res) => {
        if (res.status === 401) {
          errorToast("Your reset password link is expired");
          history.push("/reset-password");
        } else if (res.status === 200) {
          successfulToast("Password Successfully Changed");
          history.push("/login");
        } else {
          res.json().then((errorData) => {
            errorToast(`Reset Password: ${errorData.message}`);
            history.push("/reset-password");
          });
        }
      },
      null,
      (err) => {
        errorToast(
          "Unable to update your password right now. Please try again later."
        );
        console.error("error updating password", err);

        history.push("/login");
        setIsLoading(false);
      },
      null,
      false
    );
  };

  return (
    <Form onSubmit={handleUpdatePassword}>
      <Label className="label" htmlFor="new-password">
        New Password
      </Label>
      <PasswordInput
        className="input"
        id="new-password"
        name="newPassword"
        onChange={handleChange}
        value={formData.newPassword}
        error={formErrors.newPassword}
      />

      <Label className="label" htmlFor="confirm-password">
        Confirm Password
      </Label>
      <PasswordInput
        className="input"
        id="confirm-password"
        name="confirmPassword"
        onChange={handleChange}
        value={formData.confirmPassword}
        error={formErrors.confirmPassword}
      />

      <div className="buttons-wrapper">
        <Button type="submit" disabled={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default ResetPasswordForm;
