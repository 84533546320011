import InlineError from "../UI/InlineError";

const TextInput = ({ error, ...props }) => {
  return (
    <div className="text-input">
      <input type="text" {...props} />
      {error && <InlineError message={error} />}
    </div>
  );
};

export default TextInput;
