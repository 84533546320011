import { useState } from "react";

import PasswordInput from "../../inputs/PasswordInput";
import Button from "../../buttons/Button";
import Label from "../../inputs/Label";
import Form from "../../layout/Form";

import asyncAPICall from "../../../util/apiWrapper";
import { successfulToast } from "../../../util/toastNotifications";
import { validatePassword } from "../../../util/stringUtils";

import { useAuthInfo } from "../../../context/AuthContext";

const INITIAL_FORM_STATE = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const ChangePasswordForm = ({ closeModal }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuthInfo();

  const validateForm = () => {
    const errors = {};

    const { isValid, errorMsg } = validatePassword(formData.newPassword);

    if (!isValid) {
      errors.newPassword = errorMsg;
    }

    if (formData.newPassword !== formData.confirmNewPassword) {
      errors.confirmNewPassword = "passwords don't match";
    }

    if (formData.newPassword === formData.currentPassword) {
      errors.confirmNewPassword =
        "new password must be different than previous one";
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleClosePassword = () => {
    setFormData(INITIAL_FORM_STATE);
    setFormErrors({});
    closeModal();
  };

  const handleUpdatePassword = () => {
    const validationErrors = validateForm();
    if (validationErrors) {
      setFormErrors(validationErrors);
      return;
    }

    setIsLoading(true);

    const payload = {};
    payload["current_password"] = formData.currentPassword;
    payload["password"] = formData.newPassword;

    asyncAPICall(
      `/user/${user.user_id}`,
      "PUT",
      payload,
      (res) => {
        if (res.status === 200) {
          successfulToast("Updated Password");
          setIsLoading(false);
          closeModal();
        } else {
          res.json().then((errorData) => {
            setIsLoading(false);
            setFormErrors((prev) => ({
              ...prev,
              confirmNewPassword: errorData.message,
            }));
          });
        }
      },
      null,
      (err) => {
        console.error(`update password: ${err}`);
        setIsLoading(false);
      }
    );
  };

  return (
    <Form onSubmit={handleUpdatePassword}>
      <Label className="label-alternate" htmlFor="current-password">
        Current Password
      </Label>
      <PasswordInput
        className="input-alternate"
        id="current-password"
        name="currentPassword"
        onChange={handleChange}
        value={formData.currentPassword}
        error={formErrors.currentPassword}
      />

      <Label className="label-alternate" htmlFor="new-password">
        New Password
      </Label>
      <PasswordInput
        className="input-alternate"
        id="new-password"
        name="newPassword"
        onChange={handleChange}
        value={formData.newPassword}
        error={formErrors.newPassword}
      />

      <Label className="label-alternate" htmlFor="confirm-new-password">
        Confirm New Password
      </Label>
      <PasswordInput
        className="input-alternate"
        id="confirm-new-password"
        name="confirmNewPassword"
        onChange={handleChange}
        value={formData.confirmNewPassword}
        error={formErrors.confirmNewPassword}
      />

      <div className="buttons-wrapper">
        <Button
          type="button"
          onClick={handleClosePassword}
          className="button-alternate"
        >
          Cancel
        </Button>

        <Button type="submit" disabled={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default ChangePasswordForm;
