import { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ImageSelectModal from "../../modals/ImageSelectModal";
import ConfirmModal from "../../modals/ConfirmModal";
import DragDropForm from "../../forms/DragDropForm";
import TextInput from "../../inputs/TextInput";
import InlineError from "../../UI/InlineError";
import Button from "../../buttons/Button";
import Modal from "../../modals/Modal";
import Form from "../../layout/Form";

import { removeItem, useIndexedDBState } from "../../../hooks/useStorageState";
import useAbortSignal from "../../../hooks/useAbortSignal";

import asyncAPICall from "../../../util/apiWrapper";
import { successfulToast } from "../../../util/toastNotifications";
import { toBase64 } from "../../../util/toBase64";

const INITIAL_FORM_STATE = {
  name: "",
  imageUrl: "",
  imageId: "",
  soundFile: "",
  soundName: "",
  mainSpiritualGift: "",
  description: "",
  isPremium: false,
  duration: "",
  soundUrl: "",
  order: 0,
};

const MeditationForm = () => {
  const { meditationId, meditationSetId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [meditationForm, setMeditationForm] = useIndexedDBState(
    {
      ...INITIAL_FORM_STATE,
      setGrouping: meditationSetId ? meditationSetId : "",
    },

    "meditationForm"
  );
  const [formErrors, setFormErrors] = useState({
    name: "",
    mainSpiritualGift: "",
    setGrouping: "",
    description: "",
    imageUrl: "",
    sound: "",
  });
  const [isOpen, setIsOpen] = useState({
    delete: false,
    upload: false,
    imageSelect: false,
    dragDropImage: false,
    dragDropSound: false,
  });
  const [meditationSets, setMeditationSets] = useState([]);
  const [initialMeditationSetId, setInitialMeditationSetId] =
    useState(meditationSetId);

  const audioRef = useRef(null);
  const { signal } = useAbortSignal();
  const { push } = useHistory();

  const validateForm = () => {
    if (!meditationForm.mainSpiritualGift) {
      setFormErrors((prev) => ({
        ...prev,
        mainSpiritualGift: "Main spiritual gift cannot be empty",
      }));
    }
    if (!meditationForm.setGrouping) {
      setFormErrors((prev) => ({
        ...prev,
        setGrouping: "Must choose a set group",
      }));
    }
    if (!meditationForm.name) {
      setFormErrors((prev) => ({
        ...prev,
        name: "File name cannot be empty",
      }));
    }
    if (!meditationForm.description) {
      setFormErrors((prev) => ({
        ...prev,
        description: "description cannot be empty",
      }));
    }
    if (!meditationForm.imageUrl) {
      setFormErrors((prev) => ({ ...prev, imageUrl: "Image required" }));
    }
    if (!meditationForm.soundFile) {
      setFormErrors((prev) => ({ ...prev, sound: "sound required" }));
    }

    return (
      meditationForm.name &&
      meditationForm.mainSpiritualGift &&
      meditationForm.setGrouping &&
      meditationForm.name &&
      meditationForm.description &&
      meditationForm.soundFile &&
      meditationForm.imageUrl
    );
  };

  const getOrder = () => {
    const meditationSetId = meditationForm.setGrouping;

    if (meditationForm.order && initialMeditationSetId === meditationSetId) {
      return meditationForm.order;
    } else {
      return (
        meditationSets.find(
          (meditationSet) => meditationSet.meditation_set_id === meditationSetId
        ).meditations.length + 1
      );
    }
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const payload = {
      name: meditationForm.name,
      image_url: meditationForm.imageUrl,
      image_id: meditationForm.imageId,
      sound_file: meditationForm.soundFile,
      soundName: meditationForm.soundName,
      title: meditationForm.mainSpiritualGift,
      description: meditationForm.description,
      meditation_set_id: meditationForm.setGrouping,
      is_premium: meditationForm.isPremium,
      sound: meditationForm.sound,
      image: meditationForm.image,
      duration: meditationForm.duration,
      order: getOrder(),
    };

    setIsLoading(true);
    asyncAPICall(
      `/meditation${meditationId ? `/${meditationId}` : ""}`,
      meditationId ? "PUT" : "POST",
      payload,
      (res) => {
        if (res.ok) {
          successfulToast(
            meditationId ? "Meditation set edited" : "New Sound created!"
          );
          push(`/add-media/view-set/${meditationForm.setGrouping}`);
          removeItem("meditationForm");
        } else {
          push(`/add-media/view-set/${meditationForm.setGrouping}`);
        }
        removeItem("meditationForm");
      },
      null,
      (err) => {
        push(`/add-media/view-set/${meditationForm.setGrouping}`);
        console.error(`meditation creation ${err}`);
      }
    );
  };

  const handleFormMultiUpdate = (fieldsToUpdate, errorField = "") => {
    if (errorField) {
      setFormErrors((prev) => ({ ...prev, [errorField]: "" }));
    }
    setMeditationForm((prev) => ({ ...prev, ...fieldsToUpdate }));
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    handleFormMultiUpdate({ imageUrl: imageUrl, imageId: "" }, "imageUrl");
    handleFileChange(e, "image");
  };

  const handleSound = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const soundUrl = URL.createObjectURL(file);
    const audioElement = audioRef?.current;

    if (audioElement) {
      audioElement.addEventListener("loadedmetadata", function setDuration() {
        const duration = audioElement.duration;
        audioElement.removeEventListener("loadedmetadata", setDuration);
        const normalizedDuration = Math.ceil(duration / 60);

        handleFormMultiUpdate({ duration: normalizedDuration });
      });
    }

    handleFormMultiUpdate(
      {
        soundFile: file,
        soundName: fileName,
        soundUrl: soundUrl,
      },
      "sound"
    );
    handleFileChange(e, "sound");
  };

  const handleFileChange = (e, fieldName) => {
    if (e.target.files) {
      if (e.target.files[0].size < 3e7) {
        const imageFile = e.target.files[0];

        handleUpload(imageFile, fieldName);
      } else {
        successfulToast("File too large");
        handleFormMultiUpdate({ imageUrl: "", imageFile: "" });
      }
    }
  };

  const handleUpload = async (file, fieldName) => {
    if (file) {
      const file_name = file.name.slice(0, file.name.lastIndexOf("."));
      const ext = file.name.split(".")[file.name.split(".").length - 1];
      const file_type = file.type;
      await toBase64(file)
        .then((result) =>
          setMeditationForm((prev) => ({
            ...prev,
            [fieldName]: { result, file_name, file_type, ext },
          }))
        )
        .catch((err) => console.error("Uploading Avatar", err));
    }
  };

  const renderMeditationOptions = () => {
    return meditationSets.map((meditation, idx) => {
      return (
        <option key={idx} value={meditation.meditation_set_id}>
          {meditation.name}
        </option>
      );
    });
  };

  const handleDeleteSet = () => {
    setIsLoading(true);
    asyncAPICall(
      `/meditation/delete/${meditationId}`,
      "DELETE",
      null,
      (res) => {
        if (res.ok) {
          successfulToast("Meditation deleted");
          push("/add-media");
        } else {
          setIsLoading(false);
        }
        return res.json();
      },
      null,
      (err) => {
        console.error(`meditation delete ${err}`);
      }
    );
  };

  useEffect(() => {
    if (meditationId) {
      asyncAPICall(
        `/meditation/${meditationId}`,
        "GET",
        null,
        null,
        ({ results }) => {
          setMeditationForm((prev) => ({
            ...prev,
            imageUrl: results.image.url,
            imageId: results.image.image_id,
            name: results.name,
            mainSpiritualGift: results.title,
            description: results.description,
            setGrouping: results.meditation_set_id,
            isPremium: results.is_premium,
            soundName: results.name,
            soundFile: results.sound_url,
            duration: results.duration,
            order: results.order,
          }));
          setInitialMeditationSetId(results.meditation_set_id);
          setIsLoading(false);
        },
        (err) => {
          console.error(`Get Sets: ${err}`);
          setIsLoading(false);
        },
        signal
      );
    }
  }, [meditationId, signal, setMeditationForm]);

  useEffect(() => {
    asyncAPICall(
      `/meditation-sets`,
      "GET",
      null,
      null,
      ({ results }) => {
        setMeditationSets(results);
      },
      (err) => {
        console.error(`Get Sets: ${err}`);
      }
    );
  }, []);

  return (
    <div className="mediation-form-container">
      <Form onSubmit={handleSubmit}>
        <div className="file-input-wrapper">
          <button
            className="sound"
            type="button"
            onClick={() =>
              setIsOpen((prev) => ({ ...prev, dragDropSound: true }))
            }
          >
            Sound
          </button>

          <button
            type="button"
            onClick={() =>
              setIsOpen((prev) => ({ ...prev, dragDropImage: true }))
            }
          >
            Image
          </button>
        </div>

        <p
          className="image-uploader"
          onClick={() => {
            setIsOpen((prev) => ({ ...prev, imageSelect: true }));
          }}
        >
          <u>Select Uploaded Image</u>
        </p>

        <label htmlFor="recordingImg">Recording or tutorial uploaded</label>
        <div className="image-display" id="recordingImg">
          {meditationForm.soundName && (
            <div className="inner-image-display">
              <p>{meditationForm.soundName}</p>

              <div
                className="close"
                onClick={() =>
                  handleFormMultiUpdate(
                    { soundFile: "", soundName: "" },
                    "sound"
                  )
                }
              >
                <FontAwesomeIcon
                  name="imageUrl"
                  value=""
                  icon="fa-solid fa-xmark"
                />
              </div>
            </div>
          )}
        </div>

        <audio ref={audioRef} src={meditationForm?.soundUrl} />

        {formErrors.sound && <InlineError message={formErrors.sound} />}

        <label htmlFor="displayImg">Display Image</label>
        <div className="image-display" id="displayImg">
          {meditationForm.imageUrl && (
            <div className="inner-image-display">
              <img
                className="chosen-img"
                src={meditationForm.imageUrl}
                alt="Meditation set background"
              />

              <div
                className="close"
                onClick={() =>
                  handleFormMultiUpdate({ imageUrl: "", imageId: "" })
                }
              >
                <FontAwesomeIcon
                  name="imageUrl"
                  value=""
                  icon="fa-solid fa-xmark"
                />
              </div>
            </div>
          )}
        </div>

        {formErrors.imageUrl && <InlineError message={formErrors.imageUrl} />}

        <label htmlFor="spiritualGift">Main spiritual gift</label>
        <TextInput
          defaultValue={meditationForm.mainSpiritualGift}
          id="spiritualGift"
          placeholder="Subtitle"
          maxLength="25"
          onChange={(e) => {
            handleFormMultiUpdate(
              { mainSpiritualGift: e.target.value },
              "mainSpiritualGift"
            );
          }}
          error={formErrors.mainSpiritualGift}
        />

        <label htmlFor="setGrouping">Set grouping</label>
        <select
          className="set-grouping"
          id="setGrouping"
          name="setGrouping"
          onChange={(e) => {
            handleFormMultiUpdate(
              { setGrouping: e.target.value },
              "setGrouping"
            );
          }}
          value={meditationForm?.setGrouping}
          error={formErrors.setGrouping}
        >
          <option value="" disabled hidden>
            Select the meditation grouping
          </option>
          {renderMeditationOptions()}
        </select>

        {formErrors.setGrouping && (
          <InlineError message={formErrors.setGrouping} />
        )}

        <label htmlFor="file">Meditation Name</label>
        <TextInput
          defaultValue={meditationForm.name}
          id="file"
          placeholder="Enter display name for the meditation"
          maxLength="25"
          onChange={(e) => {
            handleFormMultiUpdate({ name: e.target.value }, "name");
          }}
          error={formErrors.name}
        />

        <label htmlFor="description">Description</label>
        <textarea
          className="description-field"
          defaultValue={meditationForm.description}
          id="description"
          placeholder="Enter the spiritual gifts for this file if it is for a meditation, (add a new line per item)."
          onChange={(e) => {
            handleFormMultiUpdate(
              { description: e.target.value },
              "description"
            );
          }}
        />
        {formErrors.description && (
          <InlineError message={formErrors.description} />
        )}

        <div className="is-premium-wrapper">
          <p>Is this a premium sound?</p>
          <input
            checked={meditationForm.isPremium || false}
            type="checkbox"
            id="checkbox"
            onChange={() => {
              setMeditationForm((prev) => ({
                ...prev,
                isPremium: !prev.isPremium,
              }));
            }}
          />
        </div>

        <div className="button-wrapper">
          <Button
            type="button"
            className="cancel-btn"
            onClick={() => {
              push("/add-media");
              removeItem("meditationForm");
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>

          <Button type="Submit" disabled={isLoading}>
            Submit
          </Button>
        </div>
        {meditationId && (
          <p
            className="delete-meditation"
            onClick={() => {
              setIsOpen((prev) => ({ ...prev, delete: true }));
            }}
          >
            Delete Meditation
          </p>
        )}
      </Form>

      <ConfirmModal
        isOpen={isOpen.delete}
        handleClose={() => {
          setIsOpen((prev) => ({ ...prev, delete: false }));
        }}
        handleSubmit={handleDeleteSet}
        isLoading={isLoading}
      >
        <p>
          Are you sure you want to delete your mediation
          <span> *permanently?</span>
        </p>
      </ConfirmModal>

      <ImageSelectModal
        isOpen={isOpen.imageSelect}
        handleClose={() => {
          setIsOpen((prev) => ({ ...prev, imageSelect: false }));
        }}
        handleImageUpdate={handleFormMultiUpdate}
        currentImageId={meditationForm.imageId}
      />

      <Modal
        isOpen={isOpen.dragDropImage || isOpen.dragDropSound}
        content={{
          width: "335px",
          height: "525px",
          padding: "20px",
          fontSize: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          top: "44%",
          boxSizing: "border-box",
          ...(window.innerWidth >= 930 &&
            window.innerHeight >= 582 && {
              width: "792px",
              height: "541px",
              padding: "27px",
              justifyContent: "space-evenly",
              top: "45%",
            }),
          ...(window.innerHeight <= 582 &&
            window.innerWidth >= 520 && {
              width: "792px",
              height: "541px",
              padding: "23px",
              justifyContent: "space-evenly",
              top: "47%",
              transform: "scale(0.6) translate(-83.5%, -80%)",
            }),
        }}
        overlay={{
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(49, 53, 62, 0.5)",
          zIndex: "3",
        }}
        onRequestClose={() =>
          setIsOpen((prev) => ({
            ...prev,
            dragDropImage: false,
            dragDropSound: false,
          }))
        }
      >
        <DragDropForm
          handleFile={isOpen.dragDropImage ? handleImage : handleSound}
          handleClose={() =>
            setIsOpen((prev) => ({
              ...prev,
              dragDropImage: false,
              dragDropSound: false,
            }))
          }
          accept={
            isOpen.dragDropImage
              ? [".jpeg", ".png", ".svg", ".jpg"]
              : [".mp3", ".wav", ".m4a"]
          }
          fileType={isOpen.dragDropImage ? "image" : "sound"}
        />
      </Modal>
    </div>
  );
};

export default MeditationForm;
