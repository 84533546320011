import FooterLogoColored from "../../images/resonanceFooterLogoColored.svg";
import FooterLogo from "../../images/resonanceFooterLogo.svg";

const ResonanceIcon = ({ active }) => {
  if (active) {
    return (
      <img
        className="image-hover"
        src={FooterLogoColored}
        alt="Resonance Home Footer Colored"
      />
    );
  } else {
    return <img src={FooterLogo} alt="Resonance Home Footer" />;
  }
};

export default ResonanceIcon;
