import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../buttons/Button";

const JournalCard = (props) => {
  const { children, dateTime, pushToEdit, handleEntryToDelete } = props;

  const getDate = () => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const parsedDate = new Date(dateTime);
    const day = parsedDate.getDate();
    const month = parsedDate.toLocaleString("en-US", { month: "short" });
    const year = parsedDate.getFullYear();

    const timeString = new Intl.DateTimeFormat("en-US", options).format(
      parsedDate
    );

    return `${day} ${month} ${year} | ${timeString}`;
  };

  return (
    <div className="journal-card-wrapper">
      <div className="journal-header">
        <div className="header-content">
          <div className="date-time">{getDate()}</div>
          <div className="edit-actions">
            <Button className="button-icon" onClick={pushToEdit}>
              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
            </Button>

            <Button className="button-icon" onClick={handleEntryToDelete}>
              <FontAwesomeIcon icon="fa-solid fa-trash" />
            </Button>
          </div>
        </div>
      </div>

      <div className="journal-content">{children}</div>
    </div>
  );
};

export default JournalCard;
