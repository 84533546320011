import { useEffect, useState } from "react";

import GratitudeJournalForm from "./GratitudeJournalForm";
import WhispBackground from "../../core/WhispBackground";
import HeaderOne from "../../core/HeaderOne";
import Loading from "../../core/Loading";

import useAbortSignal from "../../../hooks/useAbortSignal";

import asyncAPICall from "../../../util/apiWrapper";

const GratitudeEditEntry = (props) => {
  const [formState, setFormState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { gratitudeJournalId } = props.match.params;
  const { signal } = useAbortSignal();

  useEffect(() => {
    if (gratitudeJournalId) {
      setIsLoading(true);

      asyncAPICall(
        `/gratitude-entry/${gratitudeJournalId}`,
        "GET",
        null,
        null,
        ({ results }) => {
          setFormState({
            entryOne: results.entry_one,
            entryTwo: results.entry_two,
            entryThree: results.entry_three,
            gratitudeEntryID: results.gratitude_entry_id,
          });
          setIsLoading(false);
        },
        (err) => {
          console.error(`Get Gratitude Entry: ${err}`);
          setIsLoading(false);
        },
        signal
      );
    }
  }, [gratitudeJournalId, signal]);

  if (isLoading) {
    return <Loading>Loading Entry</Loading>;
  }

  return (
    <div className="meditation-content">
      <HeaderOne>Gratitude Journal</HeaderOne>

      <div className="page-wrapper">
        <h3>Write down three things that you are grateful for</h3>

        <GratitudeJournalForm formState={formState} />
      </div>

      <WhispBackground />
    </div>
  );
};

export default GratitudeEditEntry;
