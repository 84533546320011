import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../buttons/Button";

import useDebounce from "../../../hooks/useDebounce";

import asyncAPICall from "../../../util/apiWrapper";
import { errorToast } from "../../../util/toastNotifications";

const MeditationForm = ({ closeModal }) => {
  const [meditations, setMeditations] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMeditation, setSelectedMeditation] = useState(null);
  const [location, setLocation] = useState(null);
  const { push } = useHistory();

  const debouncedSearchTerm = useDebounce(searchTerm);

  const renderMeditations = (meditations) => {
    return meditations?.map((meditation) => {
      return (
        <div
          className={`meditation-option${
            selectedMeditation === meditation ? " selected" : ""
          }`}
          key={meditation.meditation_id}
          onClick={() => {
            setLocation({
              pathname: "/journal/add-meditation",
              state: { meditation },
            });

            setSelectedMeditation(meditation);
          }}
        >
          {meditation.name}
        </div>
      );
    });
  };

  useEffect(() => {
    asyncAPICall(
      "/meditations",
      "GET",
      null,
      null,
      ({ results }) => {
        setMeditations(results);
        setLocation(null);
      },
      (err) => console.error("Fetch Meditations Error: ", err)
    );
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.trim() !== "") {
      asyncAPICall(
        `/meditation/search?q=${debouncedSearchTerm}`,
        "GET",
        null,
        null,
        (data) => {
          setSearchResults(() => {
            return data?.results ? data.results : [];
          });

          setLocation(null);
        },
        (err) => console.error("Meditation Search Error: ", err)
      );
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="meditation-form-container">
      <p>Select Meditation</p>

      <input
        type="search"
        placeholder="... Search ..."
        onChange={(e) => setSearchTerm(e.target.value)}
        autoFocus
      />

      <div className="meditation-options-container">
        {renderMeditations(
          searchTerm && searchTerm.trim() !== "" ? searchResults : meditations
        )}
      </div>

      <div className="button-wrapper">
        <Button className="button-alternate" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          onClick={
            location
              ? () => push(location)
              : () => errorToast("Please select a meditation")
          }
        >
          Select
        </Button>
      </div>
    </div>
  );
};

export default MeditationForm;
