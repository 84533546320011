import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import Loading from "../../core/Loading";

import { useAuthInfo } from "../../../context/AuthContext";
import useAbortSignal from "../../../hooks/useAbortSignal";
import asyncAPICall from "../../../util/apiWrapper";

const PaymentConfirmation = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  const { sessionId } = props.match.params;
  const { signal } = useAbortSignal();
  const { setUser } = useAuthInfo();
  const paymentAction = props.match.path.includes("/payment-method-confirm")
    ? "Your payment method has been updated"
    : "Thank you for subscribing";

  useEffect(() => {
    const fetchUrl = `/stripe-session-status/${sessionId}`;

    asyncAPICall(
      fetchUrl,
      "GET",
      null,
      null,
      ({ user }) => {
        setUser(user);
        setIsLoading(false);
      },
      (err) => {
        console.error(`Get Sets: ${err}`);
        setIsLoading(false);
      },
      signal
    );
  }, [signal, setUser, sessionId]);

  if (isLoading) {
    return <Loading> Completing purchase, do not leave this page!! </Loading>;
  }

  return (
    <div className="confirmation-container">
      <div className="confirmation-wrapper">
        <h2>{paymentAction}. Click below to return to your subscriptions.</h2>

        <Link to={"/subscriptions"}>
          <button>My Subscriptions</button>
        </Link>
      </div>

      <WhispBackground />
    </div>
  );
};

export default PaymentConfirmation;
