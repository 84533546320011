import RequestResetPasswordForm from "../../registration/RequestResetPasswordForm";
import ResetPasswordForm from "../../registration/ResetPasswordForm";
import WhispBackground from "../../core/WhispBackground";
import ResonanceLogo from "../../UI/ResonanceLogo";

const ResetPassword = (props) => {
  const passwordResetTokenId = props.match.params.passwordResetTokenId;

  return (
    <div className="login-container">
      <ResonanceLogo />

      {passwordResetTokenId ? (
        <ResetPasswordForm passwordResetTokenId={passwordResetTokenId} />
      ) : (
        <RequestResetPasswordForm />
      )}

      <WhispBackground mode="dark" />
    </div>
  );
};

export default ResetPassword;
