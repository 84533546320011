const StopSoundIcon = ({ active }) => {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3779_2705)">
        <path
          d="M24.1071 0H2.89286C1.29576 0 0 1.29576 0 2.89286V24.1071C0 25.7042 1.29576 27 2.89286 27H24.1071C25.7042 27 27 25.7042 27 24.1071V2.89286C27 1.29576 25.7042 0 24.1071 0Z"
          fill={active ? "#E8CE80" : "#FFF"}
        />
        <path
          d="M2.89286 0.25H24.1071C25.5662 0.25 26.75 1.43383 26.75 2.89286V24.1071C26.75 25.5662 25.5662 26.75 24.1071 26.75H2.89286C1.43383 26.75 0.25 25.5662 0.25 24.1071V2.89286C0.25 1.43383 1.43383 0.25 2.89286 0.25Z"
          stroke="#171717"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3779_2705"
          x="0"
          y="0"
          width="27"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.34902 0 0 0 0 0.305882 0 0 0 0 0.235294 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3779_2705"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3779_2705"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default StopSoundIcon;
