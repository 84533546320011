import { useState } from "react";
import { useHistory } from "react-router-dom";

import TextArea from "../../inputs/TextArea";
import Button from "../../buttons/Button";
import Form from "../../layout/Form";

import useStorageState from "../../../hooks/useStorageState";
import useAbortSignal from "../../../hooks/useAbortSignal";

import asyncAPICall from "../../../util/apiWrapper";
import { errorToast } from "../../../util/toastNotifications";

const INITIAL_FORM_STATE = {
  entryOne: "",
  entryTwo: "",
  entryThree: "",
};

const GratitudeForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useStorageState(
    INITIAL_FORM_STATE,
    "gradFormData"
  );
  const [formError, setFormError] = useState(null);

  const { push } = useHistory();
  const { signal } = useAbortSignal();

  const validateForm = () => {
    for (let key in formData) {
      if (formData[key]) {
        return null;
      }
    }

    return "Enter something you're grateful for";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));

    setFormError(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationError = validateForm();

    if (validationError) {
      setFormError(validationError);
      return;
    }

    setFormError("");

    setIsLoading(true);

    const payload = {
      entry_one: formData.entryOne,
      entry_two: formData.entryTwo,
      entry_three: formData.entryThree,
    };

    asyncAPICall(
      "/gratitude-entry",
      "POST",
      payload,
      () => {
        localStorage.removeItem("gradFormData");
        push("/meditation/gates");
        setIsLoading(false);
      },
      null,
      (err) => {
        console.error("gratitude journal entry", err);
        errorToast(`gratitude journal entry: ${err}`);
      },
      signal
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <TextArea
        name="entryOne"
        rows="10"
        placeholder="1"
        value={formData.entryOne}
        onChange={handleChange}
      />

      <TextArea
        name="entryTwo"
        rows="10"
        placeholder="2"
        value={formData.entryTwo}
        onChange={handleChange}
      />

      <TextArea
        name="entryThree"
        rows="10"
        placeholder="3"
        value={formData.entryThree}
        onChange={handleChange}
      />

      {formError && <div>{formError}</div>}

      <div className="button-wrapper">
        <Button
          type="button"
          className="button-alternate"
          onClick={() => {
            push("/meditation/gates");
            localStorage.removeItem("gradFormData");
          }}
          disabled={isLoading}
        >
          Skip
        </Button>

        <Button type="submit" disabled={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default GratitudeForm;
