import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import HeaderOne from "../../core/HeaderOne";
import Loading from "../../core/Loading";
import Card from "../../core/Card";
import { useAuthInfo } from "../../../context/AuthContext";
import { renderLockOrBell } from "../../../helpers/renderLockOrBell";

import useAbortSignal from "../../../hooks/useAbortSignal";

import asyncAPICall from "../../../util/apiWrapper";

const PremiumSounds = (props) => {
  const [premiumMeditations, setPremiumMeditations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { signal } = useAbortSignal();
  const { premiumMeditationsId } = props.match.params;
  const history = useHistory();
  const { user } = useAuthInfo();

  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    asyncAPICall(
      `/premium-meditations`,
      "GET",
      null,
      null,
      ({ results }) => {
        setPremiumMeditations(results);
        setIsLoading(false);
      },
      (err) => {
        console.error(`Get Premium Meditations: ${err}`);
        setIsLoading(false);
      },
      signal
    );
  }, [premiumMeditationsId, signal]);

  const renderMeditations = () => {
    return premiumMeditations?.map((meditationSet, idx) => {
      return meditationSet.meditations.map((meditation) => {
        return (
          <Link
            to={`/add-media/meditation/${meditation.meditation_id}`}
            key={meditation.meditation_id}
          >
            {renderLockOrBell(idx, state, user?.role)}
            <Card
              src={
                meditation?.image?.thumbnail_url
                  ? meditation.image.thumbnail_url
                  : meditation.image.url
              }
              alt="Meditation set background"
            >
              <div className="name">
                {meditation?.name}
                <div className="duration">
                  {`${meditation?.duration} minute${
                    meditation?.duration !== 1 ? "s" : ""
                  }`}
                </div>
              </div>
              <div className="banner"> Found in {meditationSet.name}</div>
            </Card>
          </Link>
        );
      });
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="meditation-set-view ">
      <HeaderOne>Premium Sounds</HeaderOne>

      <div className="card-wrapper editable">
        <div className="sound-wrapper">
          <button onClick={() => history.goBack()}>Back</button>
        </div>
        <div className="meditations">{renderMeditations()}</div>
      </div>

      <WhispBackground />
    </div>
  );
};

export default PremiumSounds;
