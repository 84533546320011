import { useEffect, useState } from "react";

import logoLarge from "../../assets/images/logo-large.svg";
import logo from "../../assets/images/logo.png";

const ResonanceLogo = () => {
  const [isLargeImage, setIsLargeImage] = useState(window.innerWidth > 755);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 755) {
        setIsLargeImage(true);
      } else {
        setIsLargeImage(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <img
      src={isLargeImage ? logoLarge : logo}
      alt="Large Resonance logo"
      className="logo"
    />
  );
};

export default ResonanceLogo;
