import WhispBackground from "../../core/WhispBackground";
import HeaderOne from "../../core/HeaderOne";
import Button from "../../buttons/Button";

const Instructions = (props) => {
  return (
    <div className="meditation-content instructions-container">
      <HeaderOne>Step Three</HeaderOne>

      <div className="page-wrapper">
        <h3>Select meditation and oil</h3>

        <p>
          Now that you're in an attitude of gratitude, you are in a higher state
          of being. You are now ready to pick your meditation and oil. Selecting
          is simple.
        </p>

        <p>
          Don't over think it. Look at the oils and pictures and let them speak
          to you. Do any of them stand out? Are you drawn to one quickly?
        </p>

        <p>
          Sometimes I see a light with the oil, sometimes I run my hand across
          the tops and feel for heat, sometimes I'm drawn or feel a pull towards
          a picture.
        </p>

        <p>
          Selecting is based on your higher self letting you know what kind of
          experience you need. If you will trust your higher self to pick, after
          you meditate, you will be amazed that your choice gave you the exact
          experience you may or may not have known you needed!
        </p>

        <p>
          Breathe, clear your mind and let your higher self direct you in the
          way you need to go.
        </p>

        <p>Click on “Meditation” below to select your meditation and oil.</p>

        <div className="button-wrapper">
          <Button onClick={() => props.history.push("/meditation/sets")}>
            Meditation
          </Button>
        </div>
      </div>

      <WhispBackground />
    </div>
  );
};

export default Instructions;
