import Spinner from "../../assets/icons/custom-icons/Spinner";

const Loading = ({ children }) => {
  return (
    <div className="loading-container">
      <p>{children ? children : "Loading Content"}</p>
      <Spinner />
    </div>
  );
};

export default Loading;
