import { useState } from "react";
import { useHistory } from "react-router-dom";

import TextInput from "../../inputs/TextInput";
import TextArea from "../../inputs/TextArea";
import Button from "../../buttons/Button";
import Form from "../../layout/Form";

import useAbortSignal from "../../../hooks/useAbortSignal";

import asyncAPICall from "../../../util/apiWrapper";
import { useCurrentMeditationContext } from "../../../context/CurrentMeditationContext";
import { errorToast } from "../../../util/toastNotifications";

const INITIAL_FORM_STATE = {
  title: "",
  journalEntry: "",
};

const JournalForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formErrors, setFormErrors] = useState({});

  const { signal } = useAbortSignal();
  const { push } = useHistory();
  const { currentMeditation } = useCurrentMeditationContext();

  const validateForm = () => {
    const errors = {};

    if (!formData.title) {
      errors.title = "Enter a title for your journal entry";
    }

    if (!formData.journalEntry) {
      errors.journalEntry = "Write down your thoughts";
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));

    if (formErrors[name]) setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();
    if (validationErrors) {
      setFormErrors(validationErrors);
      return;
    }

    setFormErrors({});

    const payload = {
      meditation_id: currentMeditation.meditation_id,
      journal_entry: formData.journalEntry,
      title: formData.title,
    };

    setIsLoading(true);

    asyncAPICall(
      "/journal-entry",
      "POST",
      payload,
      (res) => {
        if (res.status === 201) {
          push("/meditation/home");
        }
        setIsLoading(false);
      },
      null,
      (err) => {
        console.error("meditation journal entry", err);
        errorToast(`meditation journal entry: ${err}`);
      },
      signal
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <TextInput
        className="input-alternate"
        name="title"
        placeholder="Title"
        value={formData.title}
        error={formErrors.title}
        onChange={handleChange}
      />

      <TextArea
        name="journalEntry"
        rows="10"
        placeholder="Journal Entry"
        value={formData.journalEntry}
        error={formErrors.journalEntry}
        onChange={handleChange}
      />

      <div className="button-wrapper">
        <Button
          type="button"
          className="button-alternate"
          onClick={() => push("/meditation/home")}
          disabled={isLoading}
        >
          Skip
        </Button>

        <Button type="submit" disabled={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default JournalForm;
