import { useState, useEffect } from "react";

import EmailInput from "../../inputs/EmailInput";
import TextInput from "../../inputs/TextInput";
import Button from "../../buttons/Button";
import Label from "../../inputs/Label";
import Form from "../../layout/Form";

import useStorageState from "../../../hooks/useStorageState";

import asyncAPICall from "../../../util/apiWrapper";
import { successfulToast, errorToast } from "../../../util/toastNotifications";
import { useAuthInfo } from "../../../context/AuthContext";
import { isValidEmail } from "../../../util/stringUtils";

const INITIAL_FORM_STATE = {
  firstName: "",
  lastName: "",
  email: "",
};

const AccountForm = () => {
  const [formData, setFormData] = useStorageState(
    INITIAL_FORM_STATE,
    "accountFormData"
  );
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser } = useAuthInfo();

  useEffect(() => {
    if (user) {
      setFormData((prev) => ({
        ...prev,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
      }));
    }
  }, [user, setFormData]);

  const validateForm = () => {
    const errors = {};

    if (
      formData.firstName === user.first_name &&
      formData.lastName === user.last_name &&
      formData.email === user.email
    ) {
      errors.email = "Nothing has been changed";
    }

    if (formData.firstName === "") {
      errors.firstName = "First name cannot be empty";
    }

    if (formData.lastName === "") {
      errors.lastName = "Last name cannot be empty";
    }

    if (formData.email === "") {
      errors.email = "Email cannot be empty";
    }

    if (!formData.email || !isValidEmail(formData.email)) {
      errors.email = "Invalid email";
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();
    if (validationErrors) {
      setFormErrors(validationErrors);
      return;
    }

    setFormErrors({});

    setIsLoading(true);

    const payload = { ...formData };

    payload["first_name"] = payload.firstName;
    payload["last_name"] = payload.lastName;

    delete payload.firstName;
    delete payload.lastName;

    asyncAPICall(
      `/user/${user.user_id}`,
      "PUT",
      payload,
      (res) => {
        if (res.status === 200) {
          successfulToast("Updated Account");
          localStorage.removeItem("accountFormData");
        }
        setIsLoading(false);
        return res.json();
      },
      (data) => {
        if (data.results) {
          setUser(data.results);
        }
      },
      (err) => {
        errorToast("Failed to update account");
        setIsLoading(false);
        console.error(`update ${err}`);
      },
      null,
      true
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));

    if (formErrors[name]) setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label className="label-alternate" htmlFor="first-name">
        First Name
      </Label>
      <TextInput
        className="input-alternate"
        id="first-name"
        name="firstName"
        onChange={handleChange}
        value={formData.firstName}
        error={formErrors.firstName}
      />

      <Label className="label-alternate" htmlFor="last-name">
        Last Name
      </Label>
      <TextInput
        className="input-alternate"
        id="last-name"
        name="lastName"
        onChange={handleChange}
        value={formData.lastName}
        error={formErrors.lastName}
      />

      <Label className="label-alternate" htmlFor="email">
        Email
      </Label>
      <EmailInput
        className="input-alternate"
        id="email"
        name="email"
        onChange={handleChange}
        value={formData.email}
        error={formErrors.email}
      />

      <Button className="button-text" disabled={isLoading} type="submit">
        Save Changes
      </Button>
    </Form>
  );
};

export default AccountForm;
