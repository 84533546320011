import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import asyncAPICall from "../../../util/apiWrapper";
import ResonanceLogo from "../../UI/ResonanceLogo";

import { successfulToast, errorToast } from "../../../util/toastNotifications";

const EmailSentPage = () => {
  const history = useHistory();
  const params = useParams();
  const verifyEmailTokenId = params.verifyEmailTokenId;

  useEffect(() => {
    if (verifyEmailTokenId) {
      asyncAPICall(
        `/user/verify/${verifyEmailTokenId}`,
        "PATCH",
        null,
        (res) => {
          if (res.status === 200) {
            successfulToast("Verification Successful, Please Log in.");
            history.push("/login");
          } else {
            throw new Error("create account failed");
          }
        },
        null,
        (err) => {
          console.error(err);
          errorToast("Something went wrong. Please Try again");
          history.push("/register");
        },
        null,
        false
      );
    }
  }, [history, verifyEmailTokenId]);

  return (
    <div className="email-sent-container">
      <ResonanceLogo />

      {verifyEmailTokenId ? (
        <p className="check-email-msg">Verifying your Email, Please Wait</p>
      ) : (
        <p className="check-email-msg">
          Please Check your Email to Verify your Account
        </p>
      )}

      <WhispBackground mode="dark" />
    </div>
  );
};
export default EmailSentPage;
