import Journal from "./Journals";

const MeditationJournal = () => {
  return (
    <Journal
      journalType="meditation"
      getEndpoint="journal-entries"
      updateEndpoint="journal-entry"
    />
  );
};

export default MeditationJournal;
