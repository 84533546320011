import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Button from "../../../components/buttons/Button";
import AudioPlayer from "../../core/AudioPlayer";

import { useCurrentMeditationContext } from "../../../context/CurrentMeditationContext";

import CircleArrow from "../../../assets/icons/custom-icons/CircleArrow";

const Meditation = (props) => {
  const [meditationStarted, setMeditationStarted] = useState(false);
  const { currentMeditation } = useCurrentMeditationContext();

  useEffect(() => {
    if (!currentMeditation) {
      props.history.push("/meditation/sets");
    }
  }, [currentMeditation, props.history]);

  return (
    <div
      className="meditation-wrapper"
      style={{ backgroundImage: `url("${currentMeditation?.image.url}")` }}
    >
      <div className={`backdrop${meditationStarted ? " hidden" : ""}`}>
        <div
          className={`instructions-container${
            meditationStarted ? " hidden" : ""
          }`}
        >
          <p>
            Put a few drops of the <b>{currentMeditation?.title}</b> essential
            oil in your hands and rub them together.
          </p>

          <p>Cup your hands near your nose and take a deep breath.</p>

          <p>
            Imagine yourself here in this place. Where would you be? What are
            you doing? How does it feel? What are you wearing? What is the air
            temperature? etc.
          </p>

          <p>
            Once you feel connected and aligned with your chosen nature and
            essential oil it's time to start the meditation.
          </p>

          <p>Take in another deep breath, and exhale slowly.</p>

          <p>Bring to your mind the gate we talked about earlier.</p>

          <p>
            Once you see your gate, you will be pressing the play button to
            start the music. When the music begins, close your eyes and see the
            gate in your mind. Simply imagine yourself walking through it. Allow
            yourself to be wherever you are, see whatever you see, feel whatever
            you feel. Be fully present in the moment.
          </p>
        </div>

        <div>
          {meditationStarted ? (
            <AudioPlayer src={currentMeditation.sound_url} />
          ) : (
            <div className="centered-button">
              <Button onClick={() => setMeditationStarted(true)}>Begin</Button>
            </div>
          )}

          <Link
            className="banner-continue"
            to="/meditation/gifts"
            style={{ display: meditationStarted ? "flex" : "none" }}
          >
            Walk out and close your gate
            <CircleArrow />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Meditation;
