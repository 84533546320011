import { useState, useEffect, useMemo } from "react";

import Spinner from "../../assets/icons/custom-icons/Spinner";
import PaginationButtons from "./PaginationButtons";
import Button from "../buttons/Button";

const ImageCarousel = (props) => {
  const {
    images,
    currentImageId,
    handleImageUpdate,
    handleClose,
    isSelectable = false,
    perPage = 4,
  } = props;

  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadedCount, setLoadedCount] = useState(0);

  const totalPages =
    images.length % perPage === 0
      ? Math.floor(images.length / perPage)
      : Math.floor(images.length / perPage) + 1;

  const imagesToRender = useMemo(
    () => images.slice(currentPage * perPage - perPage, currentPage * perPage),
    [currentPage, images, perPage]
  );

  const renderImages = () => {
    return imagesToRender.map((image, idx) => {
      return isSelectable ? (
        <img
          key={image.image_id}
          className={`selectable ${
            selectedImage
              ? selectedImage.imageId === image.image_id
                ? "selected"
                : ""
              : currentImageId === image.image_id
              ? "selected"
              : ""
          } ${loadedCount === imagesToRender.length ? "show" : ""}`}
          src={image.url}
          alt="Meditation set background"
          onLoad={() => setLoadedCount((prev) => prev + 1)}
          onClick={() =>
            setSelectedImage({
              imageId: image.image_id,
              imageUrl: image.url,
            })
          }
        />
      ) : (
        <img
          key={idx}
          className={loadedCount === imagesToRender.length ? "show" : ""}
          src={image.url}
          alt="Gate surrounded by vegetation"
          onLoad={() => setLoadedCount((prev) => prev + 1)}
        />
      );
    });
  };

  useEffect(() => {
    setLoadedCount(0);
  }, [currentPage]);

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        <div
          className={`images-wrapper ${
            perPage === 4
              ? "images-wrapper-small"
              : perPage === 6
              ? "images-wrapper-medium"
              : "images-wrapper-large"
          } ${loadedCount !== imagesToRender.length ? "loading-wrapper" : ""}`}
        >
          {loadedCount !== imagesToRender.length && <Spinner />}
          {renderImages()}
        </div>
      </div>

      {isSelectable && (
        <div className="control-buttons-wrapper">
          <PaginationButtons
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />

          <div className="save-cancel-wrapper">
            <Button
              type="button"
              className="button-alternate"
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              onClick={() => {
                selectedImage && handleImageUpdate(selectedImage, "imageUrl");
                handleClose();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;
