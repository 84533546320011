import { useState } from "react";
import { useHistory, Link } from "react-router-dom";

import PasswordInput from "../inputs/PasswordInput";
import EmailInput from "../inputs/EmailInput";
import InlineError from "../UI/InlineError";
import TextInput from "../inputs/TextInput";
import Button from "../buttons/Button";
import Label from "../inputs/Label";
import Form from "../layout/Form";

import asyncAPICall from "../../util/apiWrapper";
import { validatePassword, isValidEmail } from "../../util/stringUtils";

const INITIAL_FORM_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const RegistrationForm = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const validateForm = () => {
    const errors = {};
    const { isValid, errorMsg } = validatePassword(formData.password);

    if (!isValid) {
      errors.password = errorMsg;
    }

    if (!formData.email.trim() || !isValidEmail(formData.email)) {
      errors.email = "Invalid email";
    }

    if (formData.firstName === "") {
      errors.firstName = "First name cannot be empty";
    }

    if (formData.lastName === "") {
      errors.lastName = "Last name cannot be empty";
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();
    if (validationErrors) {
      setFormErrors(validationErrors);
      return;
    }

    setIsLoading(true);

    const payload = { ...formData };

    payload["first_name"] = payload.firstName;
    payload["last_name"] = payload.lastName;

    delete payload.firstName;
    delete payload.lastName;

    asyncAPICall(
      "/user",
      "POST",
      payload,
      (res) => {
        if (res.status === 201) {
          history.push("/verify-email");
        } else {
          throw new Error("create account failed");
        }
      },
      null,
      (err) => {
        console.error(err);
        setFormErrors((prev) => ({ loginMessage: err.message, ...prev }));
        setIsLoading(false);
      },
      null,
      false
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
    if (formErrors[name]) setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <Label htmlFor="firstName">First Name</Label>
      <TextInput
        id="firstName"
        name="firstName"
        onChange={handleChange}
        value={formData.firstName}
        error={formErrors.firstName}
      />

      <Label htmlFor="lastName">Last Name</Label>
      <TextInput
        id="lastName"
        name="lastName"
        onChange={handleChange}
        value={formData.lastName}
        error={formErrors.lastName}
      />

      <Label htmlFor="email">Email</Label>
      <EmailInput
        id="email"
        name="email"
        onChange={handleChange}
        value={formData.email}
        error={formErrors.email}
      />

      <Label htmlFor="password">Password</Label>
      <PasswordInput
        id="password"
        name="password"
        onChange={handleChange}
        value={formData.password}
        error={formErrors.password}
      />

      <div className="link-wrapper">
        <Link to="/login">Login</Link>
      </div>

      <InlineError message={formErrors.loginMessage} />
      <Button type="submit" disabled={isLoading}>
        Sign Up
      </Button>
    </Form>
  );
};

export default RegistrationForm;
