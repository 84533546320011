import { Link } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";

import middleRight from "../../../assets/images/backgrounds/light/middle-right.png";
import middleLeft from "../../../assets/images/backgrounds/light/middle-left.png";
import composer from "../../../assets/images/banners/composer.jpg";

const Composer = () => {
  return (
    <div className="about-section-container">
      <div className="banner-wrapper">
        <img src={composer} alt="Melissa Winterton Portrait" />

        <div className="banner-text">Melissa Winterton: A Life in Harmony</div>
      </div>

      <section>
        <p>
          Melissa Winterton is a multifaceted individual whose journey through
          life reads like a captivating biography. She's a mother, wife,
          businesswoman, mentor, and composer, and her life's narrative is
          marked by a deep connection to the world of music. Hailing from a
          500-acre cattle ranch, Melissa learned the values of hard work and
          responsibility from an early age. It was during her formative years
          that she discovered her innate love and gift for playing the piano. By
          the time she reached high school, she was already imparting her
          musical knowledge to a growing number of young enthusiasts through
          piano lessons.
        </p>

        <p>
          Melissa, along with her husband Thomas, has ventured into the realm of
          entrepreneurship, successfully establishing and managing several
          businesses, including a construction company, a consulting firm, and a
          series of luxury boutique motels. In 2014, Melissa embarked on a
          transformative journey of self-improvement and self-discovery, setting
          in motion a series of incredible and miraculous events that would lead
          to the discovery of her unique and powerful gift. She possesses the
          extraordinary ability to hear and play the resonant frequency within
          living entities. Despite receiving basic piano lessons from a couple
          teachers in her youth, Melissa is predominantly self-taught and draws
          inspiration from the classical greats who once composed timeless
          masterpieces.
        </p>

        <p>
          Her compositional talent has not gone unnoticed in the music industry.
          In fact, after collaborating with Melissa, a Grammy-winning songwriter
          remarked that she possesses talents and skills in composition
          reminiscent of the likes of Beethoven and Mozart.
        </p>

        <p>
          Since her momentous discovery in 2015, Melissa has composed hundreds
          of sacred sounds for individuals. Her dedication to learning and
          personal growth is unwavering, and she employs her unique gift of
          composing resonant frequencies to heal and uplift people across the
          globe. In addition to creating and composing Resonance Meditations,
          she has established a thriving practice centered on sound therapy,
          assisting individuals in achieving mental, emotional, and spiritual
          breakthroughs. Her clients travel great distances from all corners of
          the world to sit beside her Baldwin piano and experience the
          transformative power of her music.
        </p>

        <p>
          Melissa's talents have made her a sought-after artist, and she
          frequently performs private concerts for influential and prominent
          families throughout the United States. Currently residing in
          Roosevelt, Utah, with her husband and three sons, Melissa finds solace
          in travel and enjoys spending time on the sandy beaches of Florida.
        </p>

        <p>
          To delve deeper into the life and works of Melissa Winterton, and to
          stay up-to-date with her latest creations and events, visit and follow
          her on
          <a
            className="facebook"
            href="https://www.facebook.com/resonancemeditations"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          and
          <a
            className="instagram"
            href="https://www.instagram.com/wintertonmelissa"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          . Melissa's story is a testament to the extraordinary power of music
          and the profound impact it can have on the human spirit.
        </p>
      </section>

      <div className="consultation-question">
        <p>Are you interested in sound therapy with Melissa?</p>

        <Link className="consultation-button" to="/contact">
          Consultation
        </Link>
      </div>

      <div className="whisp-background-wrapper">
        <img className="whisp-mid-left" src={middleLeft} alt="" />
        <img className="whisp-mid-right" src={middleRight} alt="" />

        <WhispBackground />
      </div>
    </div>
  );
};

export default Composer;
