import { Link } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import HeaderOne from "../../core/HeaderOne";
import Card from "../../core/Card";

import Tutorial from "../../../assets/images/Tutorial.png";
import ResonanceLogo from "../../../assets/images/MeditationProcess.png";
import Piano from "../../../assets/images/piano.png";

const MeditationHome = () => {
  return (
    <div className="meditation-home-content">
      <HeaderOne>Meditation</HeaderOne>

      <div className="card-wrapper">
        <Link to="/meditation/#top">
          <Card src={Tutorial} alt="Tutorial text" className="tutorial-image">
            Resonance Meditation Tutorial
          </Card>
        </Link>

        <Link to="/meditation/gratitude">
          <Card src={ResonanceLogo} alt="Resonance Logo" className="logo">
            The Meditation Process
          </Card>
        </Link>

        <Link to="/meditation/sets">
          <Card src={Piano} alt="notes on a piano" className="piano">
            Sounds
          </Card>
        </Link>
      </div>

      <WhispBackground />
    </div>
  );
};
export default MeditationHome;
