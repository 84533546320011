const RenderMeditations = ({ selectedMeditation }) => {
  return selectedMeditation?.meditations.map((meditation) => {
    return (
      <div className="included-meditation" key={meditation.meditation_id}>
        {meditation.name}
      </div>
    );
  });
};

export default RenderMeditations;
