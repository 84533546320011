const ComboBox = ({ options, placeholder, ...props }) => {
  const renderOptions = (options) => {
    return options.map((name) => <option key={name}>{name}</option>);
  };

  return (
    <select defaultValue="DEFAULT" {...props}>
      {placeholder && (
        <option value="DEFAULT" disabled>
          {placeholder}
        </option>
      )}
      {renderOptions(options)}
    </select>
  );
};

export default ComboBox;
