import { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";

import PaginationButtons from "../../core/PaginationButtons";
import WhispBackground from "../../core/WhispBackground";
import ConfirmModal from "../../modals/ConfirmModal";
import JournalCard from "../../core/JournalCard";
import MeditationForm from "./MeditationForm";
import HeaderOne from "../../core/HeaderOne";
import ComboBox from "../../core/ComboBox";
import Loading from "../../core/Loading";
import Modal from "../../modals/Modal";

import useWindowSize from "../../../hooks/useWindowSize";
import useAbortSignal from "../../../hooks/useAbortSignal";
import asyncAPICall from "../../../util/apiWrapper";
import { capitalizeFirstLetter } from "../../../util/stringUtils";

const Journal = ({ journalType, getEndpoint, updateEndpoint }) => {
  const [journalEntries, setJournalEntries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMeditationModalOpen, setIsMeditationModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const { width } = useWindowSize();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { signal } = useAbortSignal();
  const searchParams = new URLSearchParams(search);
  const isDesc = searchParams.get("is-desc") === "true" ? true : false;
  const page =
    Number(searchParams.get("page")) > 0 ? Number(searchParams.get("page")) : 1;
  const limitPerPage = 6;
  const options = ["Newest to Oldest", "Oldest to Newest"];

  const modalHeights = {
    desktop: "650px",
    tablet: "500px",
    mobile: "400px",
  };

  const currentModalHeight =
    width >= 1440
      ? modalHeights.desktop
      : width >= 768
      ? modalHeights.tablet
      : modalHeights.mobile;

  const getUpdatedURL = () => {
    return `${pathname}?${searchParams.toString()}`;
  };

  const handlePageChange = (pageNumber) => {
    if (isNaN(pageNumber)) {
      const newPage = pageNumber(page);
      searchParams.set("page", newPage);
      history.push(getUpdatedURL());
    } else {
      searchParams.set("page", pageNumber);
      history.push(getUpdatedURL());
    }
  };

  const handleAddJournal = () => {
    if (journalType === "meditation") {
      setIsMeditationModalOpen(true);
    } else if (journalType === "gratitude") {
      history.push(`${pathname}/add`);
    }
  };

  const handleEntryToBeDeleted = (journalEntryId) => {
    setIsModalOpen(true);
    setIdToDelete(journalEntryId);
  };

  const handleDeleteEntry = (journalEntryId) => {
    setIsLoading(true);

    const deletePromise = new Promise((resolve, reject) => {
      asyncAPICall(
        `/${updateEndpoint}/delete/${journalEntryId}`,
        "DELETE",
        null,
        (res) => {
          if (res.status === 200) {
            if (journalEntries.length === 1 && page > 1) {
              resolve(page - 1);
            } else {
              resolve(page);
            }
          }
        },
        null,
        (err) => {
          reject(err);
        },
        signal
      );
    });

    const refreshJournalEntries = (pageNumber) =>
      new Promise((resolve, reject) => {
        if (page !== pageNumber) {
          searchParams.set("page", pageNumber);
          resolve(true);
        } else {
          asyncAPICall(
            `/${getEndpoint}?page=${pageNumber}&limit=${limitPerPage}${
              isDesc ? "&is_desc=True" : ""
            }`,
            "GET",
            null,
            null,
            (data) => {
              if (data.results) {
                setJournalEntries(data.results);
                setTotalPages(data.meta.total_pages);
                resolve(false);
              }
            },
            (err) => {
              reject(err);
            },
            signal
          );
        }
      });

    deletePromise
      .then(refreshJournalEntries)
      .then((shouldUpdateURL) => {
        if (shouldUpdateURL) {
          history.replace(getUpdatedURL());
        }
      })
      .catch((err) => console.error(`delete entry: ${err}`))
      .finally(() => {
        setIsLoading(false);
        setIsModalOpen(false);
      });
  };

  const renderJournalEntries = () => {
    return journalEntries.map((entry) => (
      <JournalCard
        key={entry[`${journalType}_entry_id`]}
        dateTime={entry.date_created}
        pushToEdit={() =>
          history.push(`${pathname}/edit/${entry[`${journalType}_entry_id`]}`)
        }
        handleEntryToDelete={() =>
          handleEntryToBeDeleted(entry[`${journalType}_entry_id`])
        }
      >
        {renderJournalContent(entry)}
      </JournalCard>
    ));
  };

  const renderJournalContent = (entry) => {
    if (journalType === "gratitude") {
      return (
        <div className="journal-entry-wrapper">
          <div className="entry">
            <span>1</span> {entry.entry_one}
          </div>

          <div className="entry">
            <span>2</span> {entry.entry_two}
          </div>

          <div className="entry">
            <span>3</span> {entry.entry_three}
          </div>
        </div>
      );
    } else if (journalType === "meditation") {
      return (
        <>
          <div className="meditation-title">{entry.meditation.name}</div>
          <div className="meditation-content-wrapper">
            <div className="meditation-entry-title">{entry.title}</div>
            <div className="meditation-journal-content">
              {entry.journal_entry}
            </div>
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    setIsLoading(true);
    asyncAPICall(
      `/${getEndpoint}?page=${page > 0 ? page : 1}&limit=${limitPerPage}${
        isDesc ? "&is_desc=True" : ""
      }`,
      "GET",
      null,
      null,
      (data) => {
        if (data?.results) {
          setJournalEntries(data.results);
          setTotalPages(data.meta.total_pages);
        }
        setIsLoading(false);
      },
      (err) => {
        console.error(`entries fetch ${err}`);
        setIsLoading(false);
      }
    );
  }, [page, isDesc, getEndpoint, history, journalType]);

  return (
    <div className="gratitude-journal-page">
      <HeaderOne>{`${capitalizeFirstLetter(journalType)} Journal`}</HeaderOne>

      <div className="gratitude-content-wrapper">
        <div className="sort-add-journal-container">
          <ComboBox
            options={options}
            defaultValue={isDesc ? "Newest to Oldest" : "Oldest to Newest"}
            onChange={(e) => {
              searchParams.set(
                "is-desc",
                e.target.value === "Oldest to Newest" ? false : true
              );
              history.push(getUpdatedURL());
            }}
          />
          <button onClick={handleAddJournal}>Add Journal</button>
        </div>

        {isLoading ? (
          <Loading>Loading Entries</Loading>
        ) : journalEntries.length > 0 ? (
          <>
            <div className="journal-entry-container">
              {renderJournalEntries()}
            </div>

            {totalPages > 1 && (
              <PaginationButtons
                currentPage={page}
                setCurrentPage={handlePageChange}
                totalPages={totalPages}
              />
            )}
          </>
        ) : (
          <div className="hint">
            Add a {journalType} entry by clicking the button "Add Journal" or by
            going through{" "}
            <Link to="/meditation/home">The Meditation Process</Link>
          </div>
        )}
      </div>

      <Modal
        isOpen={isMeditationModalOpen}
        onRequestClose={() => setIsMeditationModalOpen(false)}
        content={{
          width: "unset",
          height: currentModalHeight,
          fontSize: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: "300px",
          maxWidth: "500px",
          backgroundColor: "white",
        }}
        overlay={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <MeditationForm closeModal={() => setIsMeditationModalOpen(false)} />
      </Modal>

      <ConfirmModal
        handleSubmit={() => handleDeleteEntry(idToDelete)}
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        isLoading={isLoading}
      >
        <p>
          Are you sure you want to <b>Delete</b> this entry
          <span> *permanently?</span>
        </p>
      </ConfirmModal>

      <WhispBackground />
    </div>
  );
};

export default Journal;
