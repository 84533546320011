import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import TextArea from "../../inputs/TextArea";
import Button from "../../buttons/Button";
import Form from "../../layout/Form";

import useAbortSignal from "../../../hooks/useAbortSignal";
import useStorageState from "../../../hooks/useStorageState";
import asyncAPICall from "../../../util/apiWrapper";
import { errorToast } from "../../../util/toastNotifications";

const INITIAL_FORM_STATE = {
  entryOne: "",
  entryTwo: "",
  entryThree: "",
};

const GratitudeJournalForm = ({ formState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useStorageState(
    INITIAL_FORM_STATE,
    "gradJournalFormData"
  );
  const [formError, setFormError] = useState(null);

  const { goBack } = useHistory();
  const { signal } = useAbortSignal();

  const validateForm = () => {
    for (let key in formData) {
      if (formData[key]) {
        return null;
      }
    }

    return "Enter something you're grateful for";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));

    setFormError(null);
  };

  const handleSubmit = () => {
    const validationError = validateForm();

    if (validationError) {
      setFormError(validationError);
      return;
    }

    setFormError("");

    setIsLoading(true);

    const payload = {
      entry_one: formData.entryOne,
      entry_two: formData.entryTwo,
      entry_three: formData.entryThree,
    };

    asyncAPICall(
      `/gratitude-entry${formState ? `/${formState.gratitudeEntryID}` : ""}`,
      formState ? "PUT" : "POST",
      payload,
      (res) => {
        if (res.status === 201 || 200) {
          goBack();
          localStorage.removeItem("gradJournalFormData");
        }

        setIsLoading(false);
      },
      null,
      (err) => {
        console.error("gratitude journal entry", err);
        errorToast(`gratitude journal entry: ${err}`);
        setIsLoading(false);
      },
      signal
    );
  };

  useEffect(() => {
    if (formState) {
      const { entryOne, entryTwo, entryThree } = formState;

      setFormData({
        entryOne: entryOne,
        entryTwo: entryTwo,
        entryThree: entryThree,
      });
    }
  }, [formState, setFormData]);

  return (
    <Form className="gratitude-journal-form-container" onSubmit={handleSubmit}>
      <div className="text-area-container">
        <TextArea
          name="entryOne"
          rows="23"
          autoFocus
          placeholder="1"
          value={formData.entryOne}
          onChange={handleChange}
        />

        <TextArea
          name="entryTwo"
          rows="23"
          placeholder="2"
          value={formData.entryTwo}
          onChange={handleChange}
        />

        <TextArea
          name="entryThree"
          rows="23"
          placeholder="3"
          value={formData.entryThree}
          onChange={handleChange}
        />
      </div>

      {formError && <div>{formError}</div>}

      <div className="button-wrapper">
        <Button
          type="button"
          className="button-alternate"
          onClick={() => {
            goBack();
            localStorage.removeItem("gradJournalFormData");
          }}
          disabled={isLoading}
        >
          Cancel
        </Button>

        <Button type="submit" disabled={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default GratitudeJournalForm;
