import { useState } from "react";
import { useHistory } from "react-router-dom";

import TextArea from "../../inputs/TextArea";
import Button from "../../buttons/Button";
import Form from "../../layout/Form";

import useStorageState from "../../../hooks/useStorageState";
import asyncAPICall from "../../../util/apiWrapper";
import { useCurrentMeditationContext } from "../../../context/CurrentMeditationContext";
import { errorToast } from "../../../util/toastNotifications";

const SpiritualGiftsForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { currentMeditation } = useCurrentMeditationContext();
  const { push } = useHistory();

  const [description, setDescription] = useStorageState(
    currentMeditation?.custom_additional_gifts?.length > 0
      ? currentMeditation.custom_additional_gifts[0].description
      : "",
    "spiritGift"
  );

  const handleSubmit = () => {
    const payload = {
      meditation_id: currentMeditation?.meditation_id,
      description,
    };

    setIsLoading(true);

    asyncAPICall(
      `/additional-gift${
        currentMeditation?.custom_additional_gifts?.length > 0
          ? `/${currentMeditation.custom_additional_gifts[0].additional_gift_id}`
          : ""
      }`,
      currentMeditation?.custom_additional_gifts?.length > 0 ? "PUT" : "POST",
      payload,
      (res) => {
        setIsLoading(false);
        if (res.status === 200 || res.status === 201) {
          push("/meditation/journal");
        }
        localStorage.removeItem("spiritGift");
        setDescription("");
      },
      null,
      (err) => {
        console.error("gratitude journal entry", err);
        errorToast(`gratitude journal entry: ${err}`);
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <TextArea
        name="description"
        cols="30"
        rows="10"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <div className="button-wrapper">
        <Button
          type="button"
          className="button-alternate"
          onClick={() => push("/meditation/play")}
          disabled={isLoading}
        >
          Back
        </Button>

        <Button type="submit" disabled={isLoading}>
          Next
        </Button>
      </div>
    </Form>
  );
};

export default SpiritualGiftsForm;
