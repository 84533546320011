import InlineError from "../UI/InlineError";

const TextArea = ({ error, ...props }) => {
  return (
    <div className="text-area-input">
      <textarea {...props} />
      {error && <InlineError message={error} />}
    </div>
  );
};

export default TextArea;
