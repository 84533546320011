import { useEffect, useState } from "react";

export default function useStorageState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);

    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export function useIndexedDBState(defaultValue, key) {
  const [value, setValue] = useState(defaultValue);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getFormData(key);
      if (data !== null) {
        const updateBlobs = (obj) => {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              const element = obj[key];
              if (element instanceof Blob) {
                obj[key] = URL.createObjectURL(element);
              } else if (typeof element === "object" && element !== null) {
                updateBlobs(element);
              }
            }
          }
        };

        updateBlobs(data);
        setValue(data);
      }
      setIsFetching(false);
    };

    fetchData();
  }, [key]);

  useEffect(() => {
    storeFormData(value, key);
  }, [key, value]);

  return [value, setValue, isFetching];
}

function openDatabase(key) {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open("soundImageStorage");

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("formData")) {
        db.createObjectStore("formData", { keyPath: "id" });
      }
    };
  });
}

export async function storeFormData(formData, key) {
  const db = await openDatabase(key);
  const transaction = db.transaction(["formData"], "readwrite");
  const store = transaction.objectStore("formData");

  await store.put({ id: key, data: formData });
}

export async function getFormData(key) {
  const db = await openDatabase(key);
  const transaction = db.transaction(["formData"], "readonly");
  const store = transaction.objectStore("formData");

  const request = store.get(key);
  return new Promise((resolve, reject) => {
    request.onsuccess = () =>
      resolve(request.result ? request?.result.data : null);
    request.onerror = () => reject(request?.error);
  });
}

export async function removeItem(key) {
  const db = await openDatabase(key);
  const transaction = db.transaction(["formData"], "readwrite");
  const store = transaction.objectStore("formData");

  store.delete(key);
}
