import HeaderOne from "./HeaderOne";
import PremiumBanner from "../UI/PremiumBanner";
import { removeEmptyLines } from "../../util/stringUtils";

const MeditationCard = ({ meditation, showDetails = false, ...props }) => {
  const altText = `${meditation.name} Meditation set background`;

  return (
    <div className="card" {...props}>
      {meditation.is_premium && <PremiumBanner />}
      <img
        src={
          meditation.image.thumbnail_url
            ? meditation.image.thumbnail_url
            : meditation.image.url
        }
        alt={altText}
      />

      <div className="oil-time-wrapper">
        {meditation.title}

        <div className="duration">
          {`${meditation.duration} minute${
            meditation.duration !== 1 ? "s" : ""
          }`}
        </div>
      </div>

      <HeaderOne className={showDetails ? "banner" : "banner rounded"}>
        {meditation.name}
      </HeaderOne>

      {showDetails && (
        <div className="more-info-container">
          <div className="additional-gifts">Additional Spiritual Gifts</div>
          <div className="description-wrapper">{meditation.description}</div>

          {meditation.custom_additional_gifts?.length > 0 && (
            <div className="custom-gifts-wrapper">
              {removeEmptyLines(
                meditation.custom_additional_gifts[0].description
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MeditationCard;
