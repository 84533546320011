import { useState } from "react";
import { useHistory } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import EmailInput from "../../inputs/EmailInput";
import TextInput from "../../inputs/TextInput";
import PageFooter from "../../core/PageFooter";
import TextArea from "../../inputs/TextArea";
import Label from "../../inputs/Label";
import Form from "../../layout/Form";

import { errorToast, successfulToast } from "../../../util/toastNotifications";
import { isValidEmail } from "../../../util/stringUtils";
import asyncAPICall from "../../../util/apiWrapper";
import AuthServices from "../../../services/authServices";

const Contact = () => {
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("Schedule a Consultation");
  const [messageInput, setMessageInput] = useState("");

  const { checkLogin } = AuthServices;
  const history = useHistory();

  const handleSubmit = () => {
    const inputsReturn = {
      name: nameInput,
      email: emailInput,
      subject: subjectInput,
      message: messageInput,
    };

    asyncAPICall(
      "/contact-email",
      "POST",
      inputsReturn,
      (res) => {
        if (res.ok) {
          successfulToast("Contact Request Sent");
          userRedirect();
        }
        return res.json();
      },
      null,
      (error) => {
        errorToast("Request Failed", error);
      },
      null,
      false
    );
  };

  const userRedirect = () => {
    checkLogin().then((result) => {
      if (result) {
        history.push("/about");
      } else {
        history.push("/home");
      }
    });
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="contact-wrapper">
        <h2>Welcome to Resonance Meditations!</h2>
        <p>
          We're here to assist you on your mindfulness journey. If you have any
          questions, feedback, or need support, please reach out through the
          contact form below. Your input is valuable to us, and we appreciate
          your presence in our community.
        </p>

        <Form className="input-wrapper" onSubmit={handleSubmit}>
          <Label htmlFor="name">Name*</Label>

          <TextInput
            id="name"
            className="name-input"
            onChange={(e) => {
              setNameInput(e.target.value);
            }}
            value={nameInput}
          />

          <Label htmlFor="email">Email*</Label>

          <EmailInput
            id="email"
            className="email-input"
            onChange={(e) => {
              setEmailInput(e.target.value);
            }}
            value={emailInput}
          />

          <Label htmlFor="subject">Subject</Label>

          <TextInput
            id="subject"
            className="subject-input"
            onChange={(e) => {
              setSubjectInput(e.target.value);
            }}
            value={subjectInput}
          />

          <Label htmlFor="message">Message*</Label>

          <TextArea
            id="message"
            className="message-input"
            onChange={(e) => {
              setMessageInput(e.target.value);
            }}
            value={messageInput}
          />

          <div className="button-wrapper">
            <button
              type="submit"
              disabled={
                !(
                  nameInput &&
                  isValidEmail(emailInput) &&
                  subjectInput &&
                  messageInput
                )
              }
            >
              Submit
            </button>
          </div>
        </Form>
      </div>

      <WhispBackground />
      <PageFooter />
    </div>
  );
};

export default Contact;
