const authRoutes = ["/login", "/register", "/reset-password", "/verify-email"];
const publicRoutes = [
  "/about",
  "/about-composer",
  "/about-sounds",
  "/contact",
  "/home",
];

export { authRoutes, publicRoutes };
