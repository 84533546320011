import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import ResonanceIcon from "../../assets/icons/custom-icons/ResonanceIcon";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-wrapper">
        <NavLink to="/about" className="link">
          <FontAwesomeIcon icon="fa-solid fa-music" className="icon" />
        </NavLink>

        <NavLink to="/meditation/home" className="link">
          <div className="image-wrapper">
            <ResonanceIcon active={true} />
            <ResonanceIcon active={false} />
          </div>
        </NavLink>

        <NavLink to="/journal/home" className="link">
          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" className="icon" />
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
