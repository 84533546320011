import Cookies from "js-cookie";
import asyncAPICall from "../util/apiWrapper";

const loginService = async (payload, signal = null) => {
  try {
    const loginData = await new Promise((resolve, reject) => {
      asyncAPICall(
        "/auth",
        "POST",
        payload,
        (response) => {
          if (response.ok) {
            return response.json();
          } else {
            reject(response.json());
          }
        },
        (data) => {
          resolve(data);
        },
        (err) => reject(err),
        signal,
        false
      );
    });

    return loginData;
  } catch (e) {
    const json = await e;
    throw new Error(json.message);
  }
};

const logoutService = async (signal = null) => {
  try {
    const logoutData = await new Promise((resolve, reject) => {
      asyncAPICall(
        "/logout",
        "PUT",
        null,
        (res) => {
          if (res.status === 200) {
            resolve(true);
          } else {
            throw new Error("Unable to logout");
          }
        },
        null,
        () => reject(false),
        signal
      );
    });

    return logoutData;
  } catch (e) {
    throw new Error(e);
  }
};

const checkLogin = async (signal = null) => {
  if (!Cookies.get("_sid")) return false;

  try {
    const authData = await new Promise((resolve, reject) => {
      asyncAPICall(
        "/check-login",
        "GET",
        null,
        (res) => {
          if (!res.ok) {
            reject(null);
          }
          return res.json();
        },
        (data) => {
          resolve(data.results);
        },
        (err) => {
          console.error("check", err);
        },
        signal,
        true
      );
    });

    if (!authData) {
      throw new Error("No Credentials");
    }

    return authData;
  } catch (e) {
    throw new Error(e);
  }
};

const AuthServices = {
  loginService,
  logoutService,
  checkLogin,
};

export default AuthServices;
