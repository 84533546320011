import { Route, Switch, Redirect } from "react-router-dom";

import Composer from "../pages/about/Composer";
import Contact from "../pages/contact/Contact";
import Sounds from "../pages/about/Sounds";
import Header from "../navigation/Header";
import About from "../pages/about/About";
import Landing from "../pages/Landing";

import { useAuthInfo } from "../../context/AuthContext";

const PublicRoutes = () => {
  const { user } = useAuthInfo();

  return (
    <div className="body-container">
      <Route path="/" component={Header} />

      <Switch>
        <Route path="/about-composer" component={Composer} />
        <Route path="/about-sounds" component={Sounds} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        {user ? (
          <Redirect to="/meditation/home" />
        ) : (
          <Route path="/home" component={Landing} />
        )}
      </Switch>
    </div>
  );
};

export default PublicRoutes;
