import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Card from "../../core/Card";

import { renderLockOrBell } from "../../../helpers/renderLockOrBell";
import { checkIsClickable } from "../../../util/meditationSets";

import premiumBackground from "../../../assets/images/premium-sound-background.png";

const RenderSubMeditationSets = ({
  setSelectedMeditation,
  subbedSetOrder,
  meditationSets,
  setIsOpen,
  user,
}) => {
  return (
    <>
      {meditationSets.map((meditationSet, idx) => (
        <div
          className="meditation-set"
          key={meditationSet.meditation_set_id}
          onClick={() => {
            if (checkIsClickable(idx, subbedSetOrder, user?.role)) {
              setSelectedMeditation(meditationSet);
              setIsOpen(true);
            }
          }}
        >
          {renderLockOrBell(idx, subbedSetOrder, user?.role)}
          <Card
            src={
              meditationSet.image.thumbnail_url
                ? meditationSet.image.thumbnail_url
                : meditationSet.image.url
            }
          >
            {meditationSet.name}
            <div className="price">
              <h3>
                {idx === subbedSetOrder - 1
                  ? `${meditationSet.price_per_month}/month`
                  : idx === subbedSetOrder || (!subbedSetOrder && idx === 0)
                  ? `Subscribe for ${meditationSet.price_per_month}/month`
                  : idx < subbedSetOrder
                  ? `Unsubscribe first from ${
                      meditationSets[subbedSetOrder ? subbedSetOrder - 1 : 0]
                        ?.name
                    }`
                  : `Subscribe first to ${
                      meditationSets[subbedSetOrder ? subbedSetOrder : 0]?.name
                    }`}
              </h3>
            </div>
          </Card>
        </div>
      ))}
      <Link
        className="premium-card"
        to={{ pathname: "/premium-meditations", state: subbedSetOrder }}
      >
        <Card src={premiumBackground} alt="Premium meditation set background">
          Premium Sounds
        </Card>
      </Link>
    </>
  );
};

export default RenderSubMeditationSets;
