import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import TextArea from "../../inputs/TextArea";
import TextInput from "../../inputs/TextInput";
import Button from "../../buttons/Button";
import Form from "../../layout/Form";

import useStorageState from "../../../hooks/useStorageState";
import asyncAPICall from "../../../util/apiWrapper";

const INITIAL_FORM_STATE = {
  title: "",
  journalEntry: "",
};

const MeditationJournalForm = ({ meditationId, meditationJournal }) => {
  const [formData, setFormData] = useStorageState(
    INITIAL_FORM_STATE,
    "medFormData"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const { goBack } = useHistory();

  const validateForm = () => {
    let errors = {};

    if (formData.title.trim() === "") {
      errors.title = "Title entry cannot be empty.";
    }

    if (formData.journalEntry.trim() === "") {
      errors.journalEntry = "Journal entry cannot be empty.";
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();
    if (validationErrors) {
      setFormErrors(validationErrors);
      return;
    }

    setFormErrors({});

    const payload = {
      title: formData.title,
      journal_entry: formData.journalEntry,
      meditation_id: meditationId,
    };

    setIsLoading(true);

    asyncAPICall(
      `/journal-entry${
        meditationJournal ? `/${meditationJournal.meditation_entry_id}` : ""
      }`,
      meditationJournal ? "PUT" : "POST",
      payload,
      (res) => {
        if (res.status === 201 || 200) {
          setIsLoading(false);
          goBack();
          localStorage.removeItem("medFormData");
        }
      },
      null,
      (err) => console.error("add journal entry error: ", err)
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));

    if (formErrors[name]) setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  useEffect(() => {
    if (meditationJournal) {
      setFormData({
        title: meditationJournal.title,
        journalEntry: meditationJournal.journal_entry,
      });
    }
  }, [meditationJournal, setFormData]);

  return (
    <Form className="meditation-entry-form" onSubmit={handleSubmit}>
      <TextInput
        name="title"
        className="input-alternate"
        placeholder="Title"
        value={formData.title}
        onChange={handleChange}
        error={formErrors.title}
        maxLength="95"
      />

      <TextArea
        name="journalEntry"
        rows="25"
        placeholder="Journal Entry"
        value={formData.journalEntry}
        onChange={handleChange}
        error={formErrors.journalEntry}
      />

      <div className="button-wrapper">
        <Button
          type="button"
          className="button-alternate"
          onClick={() => {
            goBack();
            localStorage.removeItem("medFormData");
          }}
          disabled={isLoading}
        >
          Cancel
        </Button>

        <Button type="submit" disabled={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default MeditationJournalForm;
