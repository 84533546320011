import Button from "../buttons/Button";
import Form from "../layout/Form";
import Modal from "./Modal";

const ConfirmModal = ({
  children,
  isLoading,
  handleSubmit,
  handleClose,
  ...props
}) => {
  return (
    <Modal
      content={{
        width: "400px",
        height: "170px",
        fontSize: "20px",
        top: "21%",
      }}
      overlay={{
        backdropFilter: "blur(2px)",
        backgroundColor: "rgb(49, 53, 62, 0.5)",
        zIndex: "3",
      }}
      onRequestClose={handleClose}
      {...props}
    >
      <Form className="confirm-form" onSubmit={handleSubmit}>
        {children}

        <div>
          <Button
            type="submit"
            className="button-alternate"
            disabled={isLoading}
          >
            Yes
          </Button>

          <Button type="button" onClick={handleClose} disabled={isLoading}>
            No
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ConfirmModal;
