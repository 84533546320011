import Cookies from "js-cookie";

export const apiHost = process.env.REACT_APP_API_HOST
  ? process.env.REACT_APP_API_HOST
  : "localhost";
export const apiPort = process.env.REACT_APP_API_PORT
  ? process.env.REACT_APP_API_PORT
  : "8089";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? `https://${apiHost}`
    : `http://${apiHost}:${apiPort}`;

const methods = ["POST", "PUT", "PATCH"];

export default function asyncAPICall(
  apiEndpoint,
  method = "GET",
  body = {},
  responseCB = null,
  dataCB = null,
  catchCB = null,
  signal = null,
  requireAuthToken = true,
  headers = null,
  doNotStringifyBody = false
) {
  const authToken = Cookies.get("_sid");

  if (requireAuthToken) {
    if (!authToken) {
      console.log("Auth Token Required");
      return false;
    }
  } else {
    headers = headers || { "content-type": "application/json" };
  }

  if (authToken || !requireAuthToken) {
    if (!headers) {
      headers = {
        "content-type": "application/json",
        auth: authToken,
      };
    } else if (requireAuthToken) {
      if (!("auth" in headers)) {
        headers["auth"] = authToken;
      }
    }

    const payload = { method: method, headers: headers };

    if (methods.includes(method.toUpperCase().trim())) {
      if (doNotStringifyBody) {
        payload.body = body;
      } else {
        payload.body = JSON.stringify(body);
      }
    }

    let responseFunction = (response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403 || response.status === 401) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    };

    if (responseCB) {
      responseFunction = responseCB;
    }

    let dataFunction = () => {};

    if (dataCB) {
      dataFunction = dataCB;
    }

    let catchFunction = (error) => console.log(error);

    if (catchCB) {
      catchFunction = catchCB;
    }

    if (signal) {
      payload.signal = signal;
    }

    fetch(`${apiUrl}${apiEndpoint}`, payload)
      .then(responseFunction)
      .then(dataFunction)
      .catch(catchFunction);

    return true;
  } else {
    return false;
  }
}

export function awaitAPICall(
  apiEndpoint,
  method = "GET",
  body = {},
  responseCB = null,
  dataCB = null,
  catchCB = null,
  signal = null,
  requireAuthToken = true,
  headers = null,
  doNotStringifyBody = false
) {
  return new Promise(async (resolve, reject) => {
    let catchFunction = (error) => console.log(error);

    if (catchCB) {
      catchFunction = catchCB;
    }

    try {
      const authToken = Cookies.get("_sid");

      if (requireAuthToken) {
        if (!authToken) {
          return false;
        }
      } else {
        headers = headers ? headers : { "content-type": "application/json" };
      }

      if (authToken || !requireAuthToken) {
        if (!headers) {
          headers = {
            "content-type": "application/json",
            auth: authToken,
          };
        } else if (requireAuthToken) {
          if (!("auth" in headers)) {
            headers["auth"] = authToken;
          }
        }

        const payload = { method: method, headers: headers };

        if (methods.includes(method.toUpperCase().trim())) {
          if (doNotStringifyBody) {
            payload.body = body;
          } else {
            payload.body = JSON.stringify(body);
          }
        }

        let responseFunction = (response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 403 || response.status === 401) {
          }

          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        };

        if (responseCB) {
          responseFunction = responseCB;
        }

        let dataFunction = () => {};

        if (dataCB) {
          dataFunction = dataCB;
        }

        if (signal) {
          payload.signal = signal;
        }

        const fetchFromAPI = async () => {
          try {
            const response = await fetch(`${apiUrl}${apiEndpoint}`, payload);
            const results = await responseFunction(response);
            await dataFunction(results);
          } catch (error) {
            catchFunction(error);
            return false;
          }
        };

        fetchFromAPI();

        resolve("Fetched From Api");
        return true;
      } else {
        reject("Auth Token Required");
      }
    } catch (error) {
      catchFunction(error);
      reject(error);
    }
  });
}
