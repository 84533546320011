import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DragDrop from "../inputs/DragDrop";

export default function DragDropForm(props) {
  const { handleFile, handleClose, accept, fileType } = props;

  return (
    <div className="drag-drop-form-wrapper">
      <div className="form-header-wrapper">
        <div className="form-header">
          Upload {`${fileType.charAt(0).toUpperCase()}${fileType.slice(1)}`}
        </div>

        <FontAwesomeIcon icon="fa-solid fa-xmark" onClick={handleClose} />
      </div>

      <DragDrop
        setUploadedFile={(e) => {
          handleFile(e);
          handleClose();
        }}
        accept={accept}
      />
    </div>
  );
}
