import lightBottomRight from "../../assets/images/backgrounds/light/btm-right.png";
import lightBottomLeft from "../../assets/images/backgrounds/light/btm-left.png";
import darkBottomRight from "../../assets/images/backgrounds/dark/btm-right.svg";
import lightTopRight from "../../assets/images/backgrounds/light/top-right.png";
import darkBottomLeft from "../../assets/images/backgrounds/dark/btm-left.svg";
import lightTopLeft from "../../assets/images/backgrounds/light/top-left.png";
import darkTopRight from "../../assets/images/backgrounds/dark/top-right.svg";
import darkTopLeft from "../../assets/images/backgrounds/dark/top-left.svg";

const lightImages = [
  lightTopLeft,
  lightTopRight,
  lightBottomLeft,
  lightBottomRight,
];

const darkImages = [darkTopLeft, darkTopRight, darkBottomLeft, darkBottomRight];

const orientations = ["top-left", "top-right", "bottom-left", "bottom-right"];

function WhispBackground({ children, mode, ...props }) {
  const renderImages = (imageList) => {
    return imageList.map((img, idx) => {
      return <img key={img} src={img} className={orientations[idx]} alt="" />;
    });
  };

  return (
    <div
      className={`whisp-background-container${mode === "dark" ? " dark" : ""}`}
      {...props}
    >
      {renderImages(mode === "dark" ? darkImages : lightImages)}
    </div>
  );
}

export default WhispBackground;
