const HamburgerIcon = () => {
  return (
    <div className="hamburger">
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.0938 3.51562H0.78125C0.349756 3.51562 0 3.16587 0 2.73438V0.78125C0 0.349756 0.349756 0 0.78125 0H21.0938C21.5252 0 21.875 0.349756 21.875 0.78125V2.73438C21.875 3.16587 21.5252 3.51562 21.0938 3.51562ZM21.0938 11.3281H5.78125C5.34976 11.3281 5 10.9784 5 10.5469V8.59375C5 8.16226 5.34976 7.8125 5.78125 7.8125H21.0938C21.5252 7.8125 21.875 8.16226 21.875 8.59375V10.5469C21.875 10.9784 21.5252 11.3281 21.0938 11.3281ZM21.0938 19.1406H0.78125C0.349756 19.1406 0 18.7909 0 18.3594V16.4062C0 15.9748 0.349756 15.625 0.78125 15.625H21.0938C21.5252 15.625 21.875 15.9748 21.875 16.4062V18.3594C21.875 18.7909 21.5252 19.1406 21.0938 19.1406Z"
          fill="#171717"
        />
      </svg>
    </div>
  );
};
export default HamburgerIcon;
