import premiumBanner from "../../assets/images/Group 383.png";

const PremiumBanner = () => {
  return (
    <div className="premium-banner">
      <img src={premiumBanner} alt="Premium meditation set background" />
    </div>
  );
};

export default PremiumBanner;
