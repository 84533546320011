const HeaderOne = ({ children, backgroundImage, ...props }) => {
  return (
    <h1
      style={{
        backgroundImage: `url("${backgroundImage}")`,
      }}
      {...props}
    >
      {children}
    </h1>
  );
};

export default HeaderOne;
