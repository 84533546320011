export const noneUserLinks = [
  { linkText: "Create Account", href: "/register" },
];

export const privateMobileLinks = [
  { linkText: "Meditation Journal", href: "/journal/meditation" },
  { linkText: "Gratitude Journal", href: "/journal/gratitude" },
  { linkText: "The Meditation Process", href: "/meditation/gratitude" },
  { linkText: "Sounds", href: "/meditation/sets" },
];

export const publicMobileLinks = [
  { linkText: "Home", href: "/home" },
  { linkText: "About the Composer ", href: "/about-composer" },
  { linkText: "About the Sounds", href: "/about-sounds" },
];

export const userNavbarOptions = [
  { linkText: "Meditation", href: "/meditation" },
  { linkText: "Journals", href: "/journal" },
];
