import InlineError from "../UI/InlineError.jsx";

const EmailInput = ({ error, ...props }) => {
  return (
    <div className="text-input">
      <input type="email" {...props} />

      {error && <InlineError message={error} />}
    </div>
  );
};

export default EmailInput;
