import { Route, Redirect } from "react-router-dom";

import MeditationSetAdmin from "../pages/admin-controls/MeditationSetAdmin";
import MeditationSetView from "../pages/admin-controls/MeditationSetView";
import MeditationAdmin from "../pages/admin-controls/MeditationAdmin";
import AddMedia from "../pages/admin-controls/AddMedia";

import { useAuthInfo } from "../../context/AuthContext";

const AdminRoutes = () => {
  const { user } = useAuthInfo();
  const isAdmin = user.role === "super-admin";

  return isAdmin ? (
    <>
      <Route exact path="/add-media" component={AddMedia} />
      <Route exact path="/add-media/set" component={MeditationSetAdmin} />
      <Route exact path="/add-media/meditation" component={MeditationAdmin} />
      <Route
        path="/add-media/set/:meditationSetId"
        component={MeditationSetAdmin}
      />
      <Route
        path="/add-media/meditation/:meditationId"
        component={MeditationAdmin}
      />
      <Route
        path="/add-media/view-set/:meditationSetId"
        component={MeditationSetView}
      />
      <Route
        path="/add-media/meditation-set/meditation/:meditationSetId"
        component={MeditationAdmin}
      />
    </>
  ) : (
    <Redirect to="/meditation/home" />
  );
};

export default AdminRoutes;
