import { useState } from "react";
import { Link } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import ChangePasswordForm from "./ChangePasswordForm";
import ConfirmModal from "../../modals/ConfirmModal";
import InlineError from "../../UI/InlineError";
import HeaderOne from "../../core/HeaderOne";
import Button from "../../buttons/Button";
import AccountForm from "./AccountForm";
import Modal from "../../modals/Modal";

import { successfulToast } from "../../../util/toastNotifications";
import { useAuthInfo } from "../../../context/AuthContext";
import asyncAPICall from "../../../util/apiWrapper";

const Account = () => {
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    confirmDelete: false,
    changePassword: false,
  });

  const { logout, user } = useAuthInfo();

  const handleDeactivateAccount = () => {
    setIsLoading(true);

    asyncAPICall(
      `/user/status/${user.user_id}`,
      "PATCH",
      null,
      (res) => {
        if (res.status === 200) {
          successfulToast("Account Deleted");
          logout();
        }
        setIsLoading(false);
        setIsModalOpen((prev) => ({ ...prev, confirmDelete: false }));
      },
      null,
      (err) => {
        setFormErrors((prev) => ({ ...prev, accountError: err.message }));
      }
    );
  };

  return (
    <div className="my-account-wrapper">
      <HeaderOne>My Account</HeaderOne>

      <div className="form-wrapper">
        {formErrors.accountError && (
          <InlineError message={formErrors.accountError} />
        )}

        <AccountForm />

        {user.stripe_info?.stripe_subscription_id && (
          <div className="action-button-wrapper">
            <Link to={"/payment-method"}>
              <button>Update Payment Method</button>
            </Link>
          </div>
        )}

        <div className="action-button-wrapper">
          <Button
            onClick={() =>
              setIsModalOpen((prev) => ({ ...prev, changePassword: true }))
            }
          >
            Change Password
          </Button>

          <Button
            className="button-text"
            onClick={() =>
              setIsModalOpen((prev) => ({ ...prev, confirmDelete: true }))
            }
          >
            Delete Account
          </Button>
        </div>
      </div>

      <ConfirmModal
        isOpen={isModalOpen.confirmDelete}
        handleClose={() =>
          setIsModalOpen((prev) => ({ ...prev, confirmDelete: false }))
        }
        isLoading={isLoading}
        handleSubmit={handleDeactivateAccount}
      >
        <span>
          Are you sure you want to <b>Delete</b> your account
          <span className="red-text"> *permanently?</span>
        </span>
      </ConfirmModal>

      <Modal
        isOpen={isModalOpen.changePassword}
        onRequestClose={() =>
          setIsModalOpen((prev) => ({ ...prev, changePassword: false }))
        }
        content={{
          width: "95%",
          height: "500px",
          fontSize: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "750px",
        }}
      >
        <h2>Change Password</h2>

        <ChangePasswordForm
          closeModal={() =>
            setIsModalOpen((prev) => ({
              ...prev,
              changePassword: false,
            }))
          }
        />
      </Modal>

      <WhispBackground />
    </div>
  );
};

export default Account;
