import { useEffect } from "react";

import WhispBackground from "../../core/WhispBackground";
import HeaderOne from "../../core/HeaderOne";
import JournalForm from "./JournalForm";

import { useCurrentMeditationContext } from "../../../context/CurrentMeditationContext";

const Journal = (props) => {
  const { currentMeditation } = useCurrentMeditationContext();

  const handleContinue = () => {
    props.history.push("/meditation/home");
  };

  useEffect(() => {
    if (!currentMeditation) {
      props.history.push("/meditation/sets");
    }
  }, [currentMeditation, props.history]);

  return (
    <div className="meditation-content">
      <HeaderOne
        className="image-header"
        backgroundImage={currentMeditation?.image.url}
      >
        {currentMeditation?.name}
      </HeaderOne>

      <div className="page-wrapper">
        <h3>Meditation Journal</h3>

        <JournalForm handleContinue={handleContinue} />
      </div>

      <WhispBackground />
    </div>
  );
};

export default Journal;
