import { useEffect, useState } from "react";

import ImageCarousel from "../core/ImageCarousel";
import Modal from "./Modal";

import useWindowSize from "../../hooks/useWindowSize";

import asyncAPICall from "../../util/apiWrapper";

const ImageSelectModal = (props) => {
  const { handleClose, handleImageUpdate, currentImageId } = props;

  const [images, setImages] = useState([]);
  const { width } = useWindowSize();

  useEffect(() => {
    asyncAPICall(
      "/images",
      "GET",
      null,
      null,
      (data) => setImages(data.results),
      (err) => console.error(`images get ${err}`)
    );
  }, []);

  return (
    <Modal
      onRequestClose={handleClose}
      content={{
        width: width >= 1024 ? "65%" : "90%",
        height: width >= 520 ? "512px" : "530px",
        fontSize: "20px",
        top: "50%",
      }}
      overlay={{
        backdropFilter: "blur(2px)",
        backgroundColor: "rgb(49, 53, 62, 0.5)",
        zIndex: "3",
      }}
      {...props}
    >
      <ImageCarousel
        images={images}
        isSelectable={true}
        currentImageId={currentImageId}
        handleImageUpdate={handleImageUpdate}
        handleClose={handleClose}
        perPage={width >= 520 ? 12 : 6}
      />
    </Modal>
  );
};

export default ImageSelectModal;
