import WhiteGate from "../assets/images/gates/WhiteGate.png";
import DustyGate from "../assets/images/gates/DustyGate.png";
import GardenGate from "../assets/images/gates/GardenGate.png";
import WoodGate from "../assets/images/gates/WoodGate.png";

const gatesData = [WhiteGate, DustyGate, GardenGate, WoodGate];

export const gates = gatesData.map((gateUrl, idx) => ({
  url: gateUrl,
  id: idx,
}));
