import WhispBackground from "../../core/WhispBackground";
import MeditationForm from "./MeditationForm";
import HeaderOne from "../../core/HeaderOne";

const MeditationAdmin = (props) => {
  const { meditationId } = props.match.params;

  return (
    <div className="meditation-set-container ">
      <HeaderOne>Meditation Sets</HeaderOne>

      <h3>{meditationId ? "Edit Meditation" : "New Meditation"}</h3>
      <p className="instructions">Upload a sound recording and display image</p>

      <MeditationForm />

      <WhispBackground />
    </div>
  );
};

export default MeditationAdmin;
