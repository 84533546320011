import { useEffect, useState } from "react";

import WhispBackground from "../../core/WhispBackground";
import MeditationCard from "../../core/MeditationCard";
import HeaderOne from "../../core/HeaderOne";
import Button from "../../buttons/Button";
import Loading from "../../core/Loading";

import useAbortSignal from "../../../hooks/useAbortSignal";
import asyncAPICall from "../../../util/apiWrapper";
import { useCurrentMeditationContext } from "../../../context/CurrentMeditationContext";

const MeditationSet = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [meditations, setMeditations] = useState([]);
  const { setCurrentMeditation } = useCurrentMeditationContext();
  const { signal } = useAbortSignal();
  const { meditationSetId } = props.match.params;

  const renderMeditations = () => {
    return meditations.map((meditation) => {
      return (
        <div
          key={meditation.meditation_id}
          onClick={() => {
            setCurrentMeditation(meditation);
            props.history.push("/meditation/play");
          }}
        >
          <MeditationCard meditation={meditation} showDetails={showDetails} />
        </div>
      );
    });
  };

  useEffect(() => {
    asyncAPICall(
      `/meditation-set/${meditationSetId}`,
      "GET",
      null,
      null,
      ({ results }) => {
        setMeditations(results.meditations);
        setIsLoading(false);
      },
      (err) => {
        console.error(`Get Meditations: ${err}`);
        props.history.goBack();
      },
      signal
    );
  }, [signal, meditationSetId, props.history]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="meditation-content">
      <HeaderOne>Step Three</HeaderOne>

      <h3>Select Your Meditation</h3>

      <div className="button-wrapper">
        <Button
          onClick={() => {
            setShowDetails((prev) => !prev);
          }}
        >
          {showDetails ? "Less Info" : "More Info"}
        </Button>
      </div>

      <div className="card-wrapper">{renderMeditations()}</div>

      <WhispBackground />
    </div>
  );
};

export default MeditationSet;
