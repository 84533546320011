import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import OrderMeditationsForm from "./OrderMeditationsForm";
import WhispBackground from "../../core/WhispBackground";
import MeditationCard from "../../core/MeditationCard";
import HeaderOne from "../../core/HeaderOne";
import Loading from "../../core/Loading";
import Modal from "../../modals/Modal";
import Card from "../../core/Card";

import useAbortSignal from "../../../hooks/useAbortSignal";
import useWindowSize from "../../../hooks/useWindowSize";

import asyncAPICall from "../../../util/apiWrapper";

const MeditationSetView = (props) => {
  const [meditationSet, setMeditationSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOrderMeditationsModalOpen, setIsOrderMeditationsModalOpen] =
    useState(false);

  const { width } = useWindowSize();
  const { signal } = useAbortSignal();
  const { meditationSetId } = props.match.params;

  useEffect(() => {
    asyncAPICall(
      `/meditation-set/${meditationSetId}`,
      "GET",
      null,
      null,
      ({ results }) => {
        setMeditationSet(results);
        setIsLoading(false);
      },
      (err) => {
        console.error(`Get Sets: ${err}`);
        setIsLoading(false);
      },
      signal
    );
  }, [meditationSetId, signal]);

  const renderMeditations = () => {
    return meditationSet?.meditations?.map((meditation, idx) => {
      return (
        <Link
          to={`/add-media/meditation/${meditation.meditation_id}`}
          key={idx}
        >
          <MeditationCard meditation={meditation} showDetails={true} />
        </Link>
      );
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="meditation-set-view ">
      <HeaderOne>Meditation Sets</HeaderOne>

      <div className="card-wrapper editable">
        <div className="sound-wrapper">
          <button
            className="order-meditations"
            onClick={() => setIsOrderMeditationsModalOpen(true)}
          >
            Order Meditations
          </button>
          <Link to={`/add-media/meditation-set/meditation/${meditationSetId}`}>
            <button>Add Sound</button>
          </Link>
        </div>

        <Link className="admin-card" to={`/add-media/set/${meditationSetId}`}>
          <Card
            src={
              meditationSet?.image?.thumbnail_url
                ? meditationSet.image.thumbnail_url
                : meditationSet.image.url
            }
            alt="Meditation set background"
          >
            {meditationSet?.name}
          </Card>
          <div className="price">
            <h3>${meditationSet?.price_per_month}/month</h3>
          </div>
        </Link>
      </div>

      <div className="meditations">{renderMeditations()}</div>

      <Modal
        isOpen={isOrderMeditationsModalOpen}
        onRequestClose={() => setIsOrderMeditationsModalOpen(false)}
        content={
          width > 1024
            ? { width: "1000px", height: "600px" }
            : { width: "90%", height: "90%" }
        }
        overlay={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <OrderMeditationsForm
          closeModal={() => setIsOrderMeditationsModalOpen(false)}
          meditations={meditationSet?.meditations}
          meditationSetId={meditationSetId}
          setMeditationSet={setMeditationSet}
        />
      </Modal>
      <WhispBackground />
    </div>
  );
};

export default MeditationSetView;
