import { useState, useRef } from "react";

import CircleButton from "./CircleButton";

import ResetSoundIcon from "../../assets/icons/custom-icons/ResetSoundIcon";
import PlaySoundIcon from "../../assets/icons/custom-icons/PlaySoundIcon";
import StopSoundIcon from "../../assets/icons/custom-icons/StopSoundIcon";

const AudioPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLooping, setIsLooping] = useState(false);
  const audioRef = useRef(null);

  const handlePlay = () => {
    audioRef.current.currentTime = 0;
    audioRef.current.play();
    setIsPlaying(true);
  };

  const handleStop = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const toggleLoop = () => {
    audioRef.current.loop = !isLooping;
    setIsLooping((prev) => !prev);
  };

  return (
    <div className="custom-audio-container">
      <CircleButton
        className="circle-button play-button"
        onClick={handlePlay}
        disabled={isPlaying}
      >
        <PlaySoundIcon active={isPlaying} />
      </CircleButton>

      <CircleButton onClick={handleStop} disabled={!isPlaying}>
        <StopSoundIcon active={!isPlaying} />
      </CircleButton>

      <CircleButton onClick={toggleLoop}>
        <ResetSoundIcon active={isLooping} />
      </CircleButton>

      <audio ref={audioRef} src={src} />
    </div>
  );
};

export default AudioPlayer;
