import RegistrationForm from "../../registration/RegistrationForm";
import WhispBackground from "../../core/WhispBackground";
import ResonanceLogo from "../../UI/ResonanceLogo";

const Register = () => {
  return (
    <div className="login-container">
      <ResonanceLogo />
      <RegistrationForm />

      <WhispBackground mode="dark" />
    </div>
  );
};

export default Register;
