import Lock from "../../assets/icons/lock.svg";

const LockIcon = () => {
  return (
    <div className="lock-icon">
      <img src={Lock} alt="lock icon" />
    </div>
  );
};
export default LockIcon;
