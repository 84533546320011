import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProfileMenu from "./ProfileMenu";
import NavItem from "../core/NavItem";

import { useAuthInfo } from "../../context/AuthContext";
import {
  userNavbarOptions,
  privateMobileLinks,
  publicMobileLinks,
} from "../../helpers/navOptions";

import HamburgerIcon from "../../assets/icons/custom-icons/HamburgerIcon";
import XIcon from "../../assets/icons/custom-icons/XIcon";
import logo from "../../assets/images/resonanceLogo.svg";

const Header = () => {
  const [headerDropdown, setHeaderDropdown] = useState(false);
  const { user, logout } = useAuthInfo();

  const renderLinks = (links) => {
    return links.map((link, index) => {
      return (
        <NavItem key={index}>
          <Link to={link.href}>{link.linkText}</Link>
        </NavItem>
      );
    });
  };

  return (
    <div className="navbar-container">
      <div className="logo-wrapper">
        <img src={logo} alt="Resonance Home" />
      </div>

      <div
        className={`dropdown-icon ${headerDropdown ? "rotated" : ""}`}
        onClick={() => setHeaderDropdown((prevClick) => !prevClick)}
      >
        {headerDropdown ? <XIcon /> : <HamburgerIcon />}
      </div>

      <div className={`nav-menu ${headerDropdown ? "active" : ""}`}>
        {renderLinks(publicMobileLinks)}
        {user && renderLinks(privateMobileLinks)}

        <div className="mobile-line-break" />

        {!user ? (
          <>
            <NavItem variant="bold">
              <Link to="/register">Create Account</Link>
            </NavItem>

            <NavItem>
              <Link to="/login">Log In</Link>
            </NavItem>
          </>
        ) : (
          <>
            <NavItem variant="bold">
              <Link to="/account">My Account</Link>
            </NavItem>

            <NavItem variant="bold">
              {user.role === "user" ? (
                <Link to="/subscriptions">Subscriptions</Link>
              ) : (
                <Link to="/add-media">Add Media</Link>
              )}
            </NavItem>

            <NavItem variant="bold">
              <Link to="/login" onClick={logout}>
                Sign Out
              </Link>
            </NavItem>
          </>
        )}
      </div>

      <div className="navbar-links">
        {user && renderLinks(userNavbarOptions)}

        <NavItem>
          <Link to="/about">About</Link>
        </NavItem>

        {!user ? (
          <NavItem>
            <Link to="/login">Log In</Link>
          </NavItem>
        ) : (
          <NavItem
            className="nav-item more"
            onClick={() => setHeaderDropdown((prev) => !prev)}
          >
            More
            <FontAwesomeIcon
              icon={`fa-solid ${
                headerDropdown ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            />
          </NavItem>
        )}

        {headerDropdown && (
          <ProfileMenu
            setHeaderDropdown={setHeaderDropdown}
            headerDropdown={headerDropdown}
          >
            {user ? (
              <>
                <NavItem>
                  <Link to="/account">My Account</Link>
                </NavItem>

                <div className="line-break" />

                <NavItem>
                  {user.role === "user" ? (
                    <Link to="/subscriptions">Subscriptions</Link>
                  ) : (
                    <Link to="/add-media">Add Media</Link>
                  )}
                </NavItem>

                <div className="line-break" />

                <NavItem onClick={logout}>Sign Out</NavItem>
              </>
            ) : (
              <>
                <NavItem>
                  <Link to="/register">Sign Up</Link>
                </NavItem>

                <div className="line-break" />

                <NavItem>
                  <Link to="/login">Log In</Link>
                </NavItem>
              </>
            )}
          </ProfileMenu>
        )}
      </div>
    </div>
  );
};

export default Header;
