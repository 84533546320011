import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import WhispBackground from "../core/WhispBackground";
import MeditationCard from "../core/MeditationCard";
import PageFooter from "../core/PageFooter";
import Button from "../buttons/Button";
import Loading from "../core/Loading";

import useAbortSignal from "../../hooks/useAbortSignal";

import asyncAPICall from "../../util/apiWrapper";

import PersonMeditating from "../../assets/images/personMeditating.png";
import resonanceAppIcon from "../../assets/images/resonanceAppIcon.png";
import resonanceLogo from "../../assets/images/resonanceLogo1.jpg";

const Landing = () => {
  const [meditationSet, setMeditationSet] = useState(null);
  const [showInstructions, setShowInstructions] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const scrollTargetRef = useRef(null);
  const { signal } = useAbortSignal();

  const renderMeditations = () => {
    return meditationSet.meditations.map((meditation) => {
      return (
        <MeditationCard
          key={meditation.meditation_id}
          meditation={meditation}
        />
      );
    });
  };

  const handleScroll = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!meditationSet) {
      asyncAPICall(
        "/meditation-set-preview",
        "GET",
        null,
        null,
        ({ results }) => {
          if (Object.keys(results).length >= 1) {
            setMeditationSet(results);
          }
          setIsLoading(false);
        },
        (err) => {
          console.error(`Get Sets: ${err}`);
          setIsLoading(false);
        },
        signal,
        false
      );
    }
  }, [meditationSet, signal]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="landing-container">
      <div className="hero-section-container">
        <img
          className="person-meditating-img"
          src={PersonMeditating}
          alt="Woman sitting on grass meditating."
        />

        <div className="hero-wrapper">
          <h2>Resonance Meditations</h2>

          <p>
            Welcome to a world of healing, transformation, and alignment through
            the guidance of Resonance Meditations. Melissa Winterton's unique
            gift of composing resonant frequencies will lead you on a journey of
            self-discovery and holistic well-being.
          </p>

          <Button className="button-alternate" onClick={handleScroll}>
            Learn More
          </Button>

          <Link className="normal-button" to="/register">
            Create Account
          </Link>
        </div>
      </div>

      <div className="info-section-container">
        <div className="logo-wrapper">
          <img src={resonanceLogo} alt="Resonance Company Logo" />
        </div>

        <div className="flavor-text" ref={scrollTargetRef}>
          <div className="text-wrapper">
            <h2>Healing through Music and Sound Therapy</h2>

            <p>
              Experience the powerful impact of sacred sounds in Resonance
              Meditations. Melissa Winterton's compositions are more than music;
              they're a pathway to inner peace and harmony. Her resonant
              frequencies are meticulously crafted to resonate with your soul.
            </p>
          </div>

          <div className="text-wrapper">
            <h2>Melissa Winterton's Meditation Process</h2>

            <p>
              Melissa's process combines the harmonious blend of Meditative
              Sounds and Essential Oils for profound mental and emotional
              healing. Through her art, Melissa brings you a journey towards
              holistic well-being.
            </p>

            <p>
              Create an account now and discover the benefits of Resonance
              Meditations today.
            </p>
          </div>

          <Link className="normal-button" to="/register">
            Create Account
          </Link>

          <p>
            Enhance your experience with ease! Click the Resonance Meditations
            icon below to add a convenient shortcut to the meditations on your
            desktop or phone home screen.
          </p>

          <img
            src={resonanceAppIcon}
            alt="Resonance Mobile App Icon"
            className="resonance-app-icon"
            onClick={() => setShowInstructions((prev) => !prev)}
          />

          {showInstructions && (
            <>
              <p>For IOS, follow these instructions</p>
              <ol>
                <li>
                  Navigate to the
                  <Link to="/login" className="login-link">
                    Login
                  </Link>
                  page.
                </li>
                <li>Tap the "share" button.</li>
                <li>Tap "Add to Home Screen".</li>
                <li>
                  Tap "Add", and the app will show up on your home screen.
                </li>
              </ol>

              <p>For Android, follow these instructions</p>
              <ol>
                <li>
                  Navigate to the
                  <Link to="/login" className="login-link">
                    Login
                  </Link>
                  page.
                </li>
                <li>Tap the three dots icon at the top right.</li>
                <li>Tap "Add to Home screen".</li>
                <li>
                  Tap "Add", and the app will show up on your home screen.
                </li>
              </ol>
            </>
          )}
        </div>

        <div className="meditation-section">
          {meditationSet && meditationSet.meditations.length > 0 && (
            <>
              <h2>{meditationSet.name}</h2>

              <p>
                Experience the transformative power of Resonance Meditations
                with a subscription. Gain exclusive access to{" "}
                {meditationSet.name}, a collection of{" "}
                {meditationSet.meditations.length} enriching recordings,
                available for just ${meditationSet.price_per_month} per month.
                More meditation sets await you, each uniquely designed to deepen
                your mindfulness practice. Subscribe now to explore the full
                range of Resonance Meditations and continually discover new,
                meaningful practices that support your well-being.
              </p>

              <div className="cards">{renderMeditations()}</div>
            </>
          )}

          <p>
            Don't miss out on a path to inner peace and self-discovery. Join our
            community today with a monthly or yearly subscription to Resonance
            Meditations. Embrace the tranquility, wisdom, and healing that await
            you. Sign up now and start your journey towards a more harmonious
            life.
          </p>

          <Link className="normal-button" to="/register">
            Sign Up
          </Link>
        </div>
      </div>

      <WhispBackground />
      <PageFooter />
    </div>
  );
};

export default Landing;
