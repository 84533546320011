import { Link } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";

import sounds from "../../../assets/images/banners/sounds.jpg";
import middleRight from "../../../assets/images/backgrounds/light/middle-right.png";
import middleLeft from "../../../assets/images/backgrounds/light/middle-left.png";

const Sounds = () => {
  return (
    <div className="about-section-container">
      <div className="banner-wrapper">
        <img src={sounds} alt="Inside a cathedral with stained glass windows" />

        <div className="banner-text">
          Exploring the Sounds of Resonance Meditations
        </div>
      </div>

      <section>
        <p>
          In life, we often encounter moments where words fall short in
          capturing the depth of our emotions. This is especially true when it
          comes to the captivating sounds of Resonance Meditations. These sounds
          defy description and must be experienced to be truly understood. When
          tasked with explaining these enchanting sounds, I often find myself
          inviting others to listen and meditate alongside me. The response is
          consistently the same: words cannot adequately encapsulate the
          experience. It's as if these sounds possess a language of their own, a
          universal one, as Rossini eloquently put it: "The language of music is
          common to all generations and nations; it is understood by everybody
          since it is understood with the heart."
        </p>

        <p>
          While describing the sounds remains a challenge, I can certainly shed
          light on the creative process behind them. Understanding this process
          can deepen your appreciation of the auditory journey. When I compose
          what I consider sacred sounds, I embark on a meditative state,
          allowing me to attune to the resonant frequency of my focus. This
          resonant frequency resonates with me, akin to the tones produced when
          a crystal glass is gently stroked with water and a fingertip. It's not
          a precise match, for what I hear are not singular tones but a dynamic
          sequence of tones, seemingly alive and in constant motion. Once
          connected to this resonant frequency, I press the record button on my
          computer, close my eyes, and commence playing my piano. As my fingers
          dance across the keys, I hear a tone resonating from the soundboard of
          my piano, harmonizing with the energetic resonant frequency I'm tapped
          into. I play until the tone fades, then I open my eyes and cease
          recording. Notably, I refrain from editing the sounds. The melodies,
          chords, and music notes in the sounds may resemble music, but for me,
          it's an entirely different experience because I'm in tune with and
          playing the vibration. When I listen to the recorded sounds, it's my
          first encounter with what you hear. Occasionally, there may be notes
          that sound out of place, almost as if I played the wrong note. But
          when I attempted to edit these notes to sound more harmonious to my
          ear, the sound lost its potency. I've come to trust my gift in
          composition, acknowledging that these seemingly "out-of-place" notes
          serve a crucial role in fine-tuning the frequency to perfection.
          Mozart's words ring true: "The music is not in the notes but in the
          silence between them."
        </p>

        <p>
          I want to emphasize that the music isn't a creation of mine; I serve
          as a conduit, channeling what I hear. Resonance Meditations made its
          debut in 2017 and has since been shared and downloaded worldwide. The
          testimonials I've received over the years have been both profound and
          humbling. Resonance Meditations has proven to be a powerful tool for
          healing, serving individuals, families, groups, professionals such as
          psychologists and psychiatrists, and even institutions like treatment
          centers, schools, and professional offices. Based on the experiences
          shared with me, Resonance Meditations has a remarkable impact:
        </p>

        <ul>
          <li>The sounds aid in the healing process.</li>
          <li>
            They transcend boundaries, whether political, social, economic, or
            religious.
          </li>
          <li>They encourage listening with the heart rather than the head.</li>
          <li>They possess the ability to soften hearts</li>
          <li>They are a form of light.</li>
          <li>They awaken the greatness within.</li>
          <li>They facilitate communication with one's higher self.</li>
          <li>They bring a sense of calm and peace.</li>
          <li>They spark creativity and a sense of purpose.</li>
          <li>They cater to each individual's unique needs.</li>
        </ul>

        <p>
          The Resonance Meditation Process, driven by these evocative sounds,
          bridges the mind and soul, allowing communication to flow between the
          two. During these meditations, you may experience messages from your
          soul to your conscious mind, conveyed as thoughts, memories, emotions,
          visuals, sounds, and even open conversations.
        </p>
      </section>

      <div className="consultation-question">
        <p>Are you interested in sound therapy with Melissa?</p>

        <Link className="consultation-button" to="/contact">
          Consultation
        </Link>
      </div>

      <div className="whisp-background-wrapper">
        <img className="whisp-mid-left" src={middleLeft} alt="" />
        <img className="whisp-mid-right" src={middleRight} alt="" />

        <WhispBackground />
      </div>
    </div>
  );
};

export default Sounds;
