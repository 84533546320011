const checkIsClickable = (idx, subbedSetOrder, userRole) => {
  if (
    idx + 1 <= subbedSetOrder ||
    idx === subbedSetOrder ||
    userRole === "super-admin"
  ) {
    return true;
  } else {
    return false;
  }
};

const calculateTotalSubscriptionCost = (meditationSets, subbedSetOrder) => {
  let pricesTotal = 0;

  meditationSets.forEach((meditation) => {
    if (meditation.order <= subbedSetOrder) {
      pricesTotal += meditation.price_per_month;
    }
  });

  return pricesTotal.toFixed(2);
};

export { checkIsClickable, calculateTotalSubscriptionCost };
