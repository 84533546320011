const PlaySoundIcon = ({ active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="33"
      viewBox="0 0 27 33"
      fill="none"
    >
      <g filter="url(#filter0_d_2365_2132)">
        <path
          d="M25.5784 13.4379L4.36351 0.895847C2.63981 -0.122708 0 0.865712 0 3.38498V28.4631C0 30.7232 2.45297 32.0853 4.36351 30.9523L25.5784 18.4162C27.4709 17.3012 27.4769 14.5529 25.5784 13.4379Z"
          fill={active ? "#E8CE80" : "#FFF"}
        />
        <path
          d="M25.4512 13.6531L25.4518 13.6535C27.185 14.6715 27.1805 17.1821 25.4515 18.2008L25.4512 18.201L4.23633 30.737L4.23599 30.7372C2.49008 31.7727 0.25 30.5272 0.25 28.4631V3.38498C0.25 2.22494 0.851921 1.43222 1.64655 1.03764C2.4508 0.638287 3.45167 0.647436 4.23629 1.11105C4.2363 1.11106 4.23632 1.11107 4.23633 1.11108L25.4512 13.6531Z"
          stroke="#171717"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2365_2132"
          x="0"
          y="0.5"
          width="27"
          height="31.8594"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.34902 0 0 0 0 0.305882 0 0 0 0 0.235294 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2365_2132"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2365_2132"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PlaySoundIcon;
