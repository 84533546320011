import { Switch, Route, Redirect } from "react-router-dom";

import MeditationHome from "../pages/meditation/MeditationHome";
import MeditationSets from "../pages/meditation/MeditationSets";
import SpiritualGifts from "../pages/meditation/SpiritualGifts";
import MeditationSet from "../pages/meditation/MeditationSet";
import Instructions from "../pages/meditation/Instructions";
import Meditation from "../pages/meditation/Meditation";
import Gratitude from "../pages/meditation/Gratitude";
import Journal from "../pages/meditation/Journal";
import Gates from "../pages/meditation/Gates";
import PremiumSounds from "../pages/meditation/PremiumSounds";

import { CurrentMeditationProvider } from "../../context/CurrentMeditationContext";

const MeditationRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/meditation" to="/meditation/home" />

      <Route path="/meditation/home" component={MeditationHome} />
      <Route path="/meditation/gratitude" component={Gratitude} />
      <Route path="/meditation/gates" component={Gates} />
      <Route path="/meditation/instructions" component={Instructions} />
      <Route exact path="/meditation/sets" component={MeditationSets} />
      <Route exact path="/premium-meditations" component={PremiumSounds} />

      <CurrentMeditationProvider>
        <Route
          path="/meditation/sets/:meditationSetId"
          component={MeditationSet}
        />
        <Route path="/meditation/play" component={Meditation} />
        <Route path="/meditation/gifts" component={SpiritualGifts} />
        <Route path="/meditation/journal" component={Journal} />
      </CurrentMeditationProvider>
    </Switch>
  );
};

export default MeditationRoutes;
