import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import WhispBackground from "../../core/WhispBackground";
import HeaderOne from "../../core/HeaderOne";
import Loading from "../../core/Loading";
import Card from "../../core/Card";

import useAbortSignal from "../../../hooks/useAbortSignal";
import asyncAPICall from "../../../util/apiWrapper";

import premiumBackground from "../../../assets/images/premium-sound-background.png";

const AddMedia = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [meditationSets, setMeditationSets] = useState([]);
  const { signal } = useAbortSignal();

  const renderMeditationSets = () => {
    return meditationSets.map((meditationSet) => {
      return (
        <Link
          className="admin-card"
          key={meditationSet.meditation_set_id}
          to={`/add-media/view-set/${meditationSet.meditation_set_id}`}
        >
          <Card
            src={
              meditationSet.image.thumbnail_url
                ? meditationSet.image.thumbnail_url
                : meditationSet.image.url
            }
            alt="Meditation set background"
            cardClass="meditation-card"
          >
            {meditationSet.name}
          </Card>
          <div className="price">
            <h3>${meditationSet.price_per_month}/month</h3>
          </div>
        </Link>
      );
    });
  };

  useEffect(() => {
    asyncAPICall(
      "/meditation-sets",
      "GET",
      null,
      null,
      ({ results }) => {
        setMeditationSets(results);
        setIsLoading(false);
      },
      (err) => {
        console.error(`Get Sets: ${err}`);
        setIsLoading(false);
      },
      signal
    );
  }, [signal]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="meditation-content">
      <HeaderOne>Meditation Sets</HeaderOne>

      <div className="admin-btn-wrapper">
        <Link to={"add-media/set"}>
          <button className="add-set">Add Set</button>
        </Link>

        <Link to={"add-media/meditation"}>
          <button>Add Sound</button>
        </Link>
      </div>

      <div className="card-wrapper editable">
        {renderMeditationSets()}
        <Link className="admin-card premium" to={`/premium-meditations`}>
          <Card src={premiumBackground} alt="Premium meditation set background">
            Premium Sounds
          </Card>
        </Link>
      </div>

      <WhispBackground />
    </div>
  );
};

export default AddMedia;
