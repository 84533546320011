import { useState } from "react";
import { Link } from "react-router-dom";

import PasswordInput from "../inputs/PasswordInput";
import EmailInput from "../inputs/EmailInput";
import Button from "../buttons/Button";
import Label from "../inputs/Label";
import Form from "../layout/Form";

import { useAuthInfo } from "../../context/AuthContext";

const INITIAL_FORM_STATE = {
  email: "",
  password: "",
};

const LoginForm = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const { login, errorMsg, isLoading } = useAuthInfo();

  const handleSubmit = () => {
    const payload = { ...formData };

    login(payload);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <Label htmlFor="email">Email</Label>
      <EmailInput
        id="email"
        name="email"
        onChange={handleChange}
        value={formData.email}
      />

      <Label htmlFor="password">Password</Label>
      <PasswordInput
        id="password"
        name="password"
        onChange={handleChange}
        value={formData.password}
      />

      <div className="link-wrapper">
        <Link to="/reset-password">Forgot Password</Link>
      </div>

      {errorMsg && <div className="error-message">{errorMsg}</div>}

      <Button type="submit" disabled={isLoading}>
        Login
      </Button>
    </Form>
  );
};

export default LoginForm;
