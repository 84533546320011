const Card = ({ children, src, alt, className, ...props }) => {
  return (
    <div
      className={`card ${props.cardClass === undefined ? "" : props.cardClass}`}
    >
      <img src={src} alt={alt} className={className} />
      <div className="title">{children}</div>
    </div>
  );
};

export default Card;
