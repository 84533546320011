import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Card from "../../core/Card";

import { renderLockOrBell } from "../../../helpers/renderLockOrBell";
import { checkIsClickable } from "../../../util/meditationSets";

import premiumBackground from "../../../assets/images/premium-sound-background.png";

const RenderMeditationSets = ({
  setSelectedMeditation,
  subbedSetOrder,
  meditationSets,
  setIsOpen,
  user,
}) => {
  return (
    <>
      {meditationSets.map((meditationSet, idx) => {
        if (
          (checkIsClickable(idx, subbedSetOrder, user.role) &&
            idx !== subbedSetOrder) ||
          user.role === "super-admin"
        ) {
          return (
            <Link
              key={meditationSet.meditation_set_id}
              to={`/meditation/sets/${meditationSet.meditation_set_id}`}
            >
              {renderLockOrBell(idx, subbedSetOrder, user.role)}
              <Card
                src={
                  meditationSet.image.thumbnail_url
                    ? meditationSet.image.thumbnail_url
                    : meditationSet.image.url
                }
                alt="Meditation set background"
              >
                {meditationSet.name}
              </Card>
            </Link>
          );
        } else {
          return (
            <div
              className="meditation-set"
              key={meditationSet.meditation_set_id}
              onClick={() => {
                if (checkIsClickable(idx, subbedSetOrder, user.role)) {
                  setSelectedMeditation(meditationSet);
                  setIsOpen(true);
                }
              }}
            >
              {renderLockOrBell(idx, subbedSetOrder, user.role)}
              <Card
                src={
                  meditationSet.image.thumbnail_url
                    ? meditationSet.image.thumbnail_url
                    : meditationSet.image.url
                }
                alt={"meditation cover"}
              >
                {meditationSet.name}
              </Card>
            </div>
          );
        }
      })}
      <Link
        className="premium-card"
        to={{ pathname: "/premium-meditations", state: subbedSetOrder }}
      >
        <Card src={premiumBackground} alt="Premium meditation set background">
          Premium Sounds
        </Card>
      </Link>
    </>
  );
};

export default RenderMeditationSets;
