import { createContext, useContext, useState } from "react";

const CurrentMeditationContext = createContext();

export const CurrentMeditationProvider = ({ children }) => {
  const [currentMeditation, setCurrentMeditation] = useState(null);

  const values = { currentMeditation, setCurrentMeditation };

  return (
    <CurrentMeditationContext.Provider value={values}>
      {children}
    </CurrentMeditationContext.Provider>
  );
};

export const useCurrentMeditationContext = () => {
  return useContext(CurrentMeditationContext);
};
