import { useEffect } from "react";

import useClickOutside from "../../hooks/useClickOutside";

const ProfileMenu = ({ children, headerDropdown, setHeaderDropdown }) => {
  const menuRef = useClickOutside(() => setHeaderDropdown(false));

  useEffect(() => {
    if (headerDropdown) {
      menuRef.current.focus();
    }
  }, [headerDropdown, menuRef]);

  return (
    <div
      ref={menuRef}
      id="dropdown-menu-wrapper"
      className="dropdown-menu-wrapper"
    >
      {children}
    </div>
  );
};

export default ProfileMenu;
