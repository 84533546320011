import { Link } from "react-router-dom";

import RenderMeditations from "../pages/meditation/RenderMeditations";
import Modal from "./Modal";

import { capitalizeFirstLetter } from "../../util/stringUtils";

export default function SubscriptionModal({
  selectedMeditation,
  changeSubscription,
  setConfirmButtons,
  confirmButtons,
  subbedSetOrder,
  setIsOpen,
  subType,
  isOpen,
  user,
}) {
  const renderConfirmButtons = () => {
    return confirmButtons ? (
      <div className="button-wrapper">
        {user.stripe_info?.stripe_subscription_id ? (
          <button
            className="button-alternate"
            onClick={() => {
              setConfirmButtons(false);
              setIsOpen(false);
              changeSubscription(
                subType,
                selectedMeditation?.order === 1 && true
              );
            }}
          >
            Yes
          </button>
        ) : (
          <Link to={`/payment/${selectedMeditation.stripe_price_id}`}>Yes</Link>
        )}

        <button
          onClick={() => {
            setConfirmButtons(false);
          }}
        >
          No
        </button>
      </div>
    ) : (
      (selectedMeditation?.order === subbedSetOrder ||
        selectedMeditation?.order === subbedSetOrder + 1) && (
        <button onClick={() => setConfirmButtons(true)}>
          {capitalizeFirstLetter(subType)}
        </button>
      )
    );
  };

  return (
    <Modal
      content={{
        width: "95%",
        fontSize: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "400px",
        height: "fit-content",
        maxHeight: "85vh",
        minHeight: "85vh",
        border: "none",
        boxShadow: "1px 2px 6px -1px #000000",
        backgroundColor: "#f8f5ed",
      }}
      overlay={{
        backdropFilter: "blur(2px)",
        backgroundColor: "rgb(49, 53, 62, 0.5)",
        zIndex: "3",
      }}
      onRequestClose={() => {
        setIsOpen(false);
        setConfirmButtons(false);
      }}
      isOpen={isOpen}
    >
      <div className="subscription-modal-container">
        <img
          className="modal-image"
          alt={selectedMeditation?.image?.name}
          src={selectedMeditation?.image?.url}
        />

        <div className="meditation-set-name">
          <h3>
            {`${capitalizeFirstLetter(subType)} ${
              subType === "subscribe" ? "to" : "from"
            } ${selectedMeditation?.name}`}
          </h3>
        </div>

        <div className="price">
          ${selectedMeditation?.price_per_month}/month
        </div>

        {confirmButtons &&
          (subType === "subscribe" ? (
            <div className="cancel-sub-info">
              <h2>Subscribe</h2>
              <p>
                Subscribe to {selectedMeditation?.name} for $
                {selectedMeditation?.price_per_month}
                /month? Your next invoice will reflect these changes.
              </p>
            </div>
          ) : (
            <div className="cancel-sub-info">
              <h2>Cancel Subscription</h2>
              <p>
                {`Are you sure you want to cancel your subscription to 
                ${selectedMeditation?.name}?`}
              </p>
            </div>
          ))}

        {renderConfirmButtons()}

        {!confirmButtons && (
          <div className="included-meditations-wrapper">
            <RenderMeditations selectedMeditation={selectedMeditation} />
          </div>
        )}
      </div>
    </Modal>
  );
}
